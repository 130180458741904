import React from "react";

const ErrorCard = ({ statusError, errorName }) => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100 w-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold">{statusError}</h1>
        <p className="fs-3">
          <span className="text-danger">خطا!</span> {errorName}
        </p>
      </div>
    </div>
  );
};

export default ErrorCard;
