import React, { useEffect, useState } from "react";
import axios from "../../services/axios";
import { Modal, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ErrorCard from "../../components/Error/ErrorCard";
import Loading from "../../components/loading/Loading";

const ContactUs = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const [data, setData] = useState({ items: [], totalCount: 0 });
  const [errorName, setErrorName] = useState("");
  const [statusError, setStatusError] = useState("");
  const [show, setShow] = useState(false);
  const [selectedModalId, setSelectedModalId] = useState(0);
  const [selectedModalName, setSelectedModalName] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [orderByType, setOrderByType] = useState("desc");

  //start pagination
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setCurrentItems(data.items);
    setPageCount(Math.ceil(data.totalCount / pageSize));
  }, [itemOffset, pageSize, pageNum, data]);

  const handlePageClick = (event) => {
    const pn = event.selected + 1;
    const newOffset = (pn * pageSize) % data.totalCount;
    setItemOffset(newOffset);
    setPageNum(pn);
  };
  //end of pagination

  const getData = async () => {
    return new Promise((resolve) => {
      axios
        .get("/Api/V1/Admin/ContactUs", {
          headers: {
            Authorization: token,
            SubSystemKey: subsystemKey,
          },
          params: {
            orderby: `${orderBy}`,
            orderbytype: `${orderByType}`,
            page: `${pageNum}`,
            pagesize: `${pageSize}`,
          },
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          // resolve({ items: [], totalCount: 0 });
          setErrorName(error.response.data.message);
          setStatusError(error.response.status);
        });
    });
  };

  const fetchApi = async () => {
    setData(await getData());
  };

  useEffect(() => {
    fetchApi();
  }, [orderBy, orderByType, pageNum, pageSize]);

  const deleteData = async (id) => {
    const response = await axios.get(
      `/Api/V1/Admin/ContactUs/delete-contactus/${id}`,
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    console.log(response.data.data);
    return response.data.data;
  };

  const handleOrder = async (fieldName) => {
    if (fieldName === orderBy) {
      setOrderByType(orderByType === "desc" ? "asc" : "desc");
    } else {
      setOrderByType("desc");
      setOrderBy(fieldName);
    }
  };

  const handleNo = () => setShow(false);
  const handleShow = (id, name) => {
    setSelectedModalId(id);
    setSelectedModalName(name);
    setShow(true);
  };

  const handleYes = async () => {
    await deleteData(selectedModalId);
    setShow(false);
    setData(await getData());
  };

  //loading
  const [loader, setLoader] = useState(false);
  const refreshHandler = () => {
    setLoader(true);
    fetchApi();
    setLoader(false);
  };

  return !errorName ? (
    loader ? (
      <Loading />
    ) : (
      <div className="my-2 mx-5 w-100">
        <div className="d-flex justify-content-center">
          <h2 className="text-primary">مدیریت تماس با ما</h2>
        </div>
        <div className="d-flex w-100 justify-content-end">
          <Button
            className="create__btn"
            variant="success"
            onClick={refreshHandler}
          >
            <i className="fa fa-refresh"></i>
          </Button>
        </div>
        <Table striped bordered className="mt-2">
          <thead>
            <tr>
              <th onClick={() => handleOrder("name")}>نام</th>
              <th onClick={() => handleOrder("subject")}>موضوع</th>
              <th onClick={() => handleOrder("email")}>ایمیل</th>
              <th onClick={() => handleOrder("createDate")}>تاریخ ایجاد</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td className="th">{item.subject}</td>
                <td className="th">{item.email}</td>
                <td>{item.createDate}</td>
                <td>
                  <Link to={`/contactUs/contactUs-info/${item.id}`}>
                    <Button className="action__btn" variant="primary" size="sm">
                      نمایش
                    </Button>
                  </Link>
                  <Button
                    onClick={() => handleShow(item.id, item.title)}
                    className="action__btn mx-1"
                    variant="danger"
                    size="sm"
                  >
                    حذف
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="d-flex">
          <ReactPaginate
            breakLabel="..."
            nextLabel="صفحه بعد >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< صفحه قبل"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
          />
          <select
            value={pageSize}
            onChange={(e) => setPageSize(e.target.value)}
            className="selector h-25 mx-2 rounded text-primary border-primary"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        <Modal
          show={show}
          onHide={handleNo}
          animation={false}
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {" "}
            آیا از حذف کردن{" "}
            <span className="text-danger fw-bold">
              {selectedModalName}
            </span>{" "}
            مطمئن هستید ؟
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleNo}>
              خیر
            </Button>
            <Button variant="primary" onClick={handleYes}>
              بله
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  ) : (
    <ErrorCard statusError={statusError} errorName={errorName} />
  );
};

export default ContactUs;
