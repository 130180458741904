import React, { useEffect, useState } from "react";
import axios from "../../services/axios";
import { Button, Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const ContactUsInfo = () => {
  const [infoData, setInfoData] = useState({
    id: "",
    email: "",
    name: "",
    mobile: "",
    subject: "",
    createDate: "",
    lastUpdate: "",
    text: "",
  });
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const { id } = useParams();

  const GetInfo = async () => {
    const response = await axios.get(
      `/Api/V1/Admin/ContactUs/contactus-info/${id}`,
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setInfoData(await GetInfo());
    };
    fetchApi();
  }, []);

  return (
    <div className="container">
      <nav
        aria-label="breadcrumb"
        className="d-flex justify-content-start fw-bold m-1"
      >
        <ol className="breadcrumb">
          <li>
            <Link to="/dashbord" className=" text-decoration-none">
              داشبورد /
            </Link>
          </li>
          <li>
            <Link to="/contactUs" className=" text-decoration-none me-1">
              تماس با ما /
            </Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page">
            نمایش جزئیات پیام 
          </li>
        </ol>
      </nav>
      <Card>
        <Card.Header className="fw-bold">جزئیات پیام </Card.Header>
        <Card.Body>
          <Card.Title>
            {" "}
            <span className="text-primary">نام : </span> {infoData.name}
          </Card.Title>
          <Card.Text>
            <span className="text-primary">ایمیل : </span> {infoData.email}
          </Card.Text>
          <Card.Text>
            <span className="text-primary">شناسه : </span> {infoData.id}
          </Card.Text>
          <Card.Text>
            <span className="text-primary">تلفن : </span> {infoData.mobile}
          </Card.Text>
          <Card.Text>
            <span className="text-primary">موضوع : </span> {infoData.subject}
          </Card.Text>
          <Card.Text>
            <span className="text-primary">تاریخ ارسال پیام : </span>{" "}
            {infoData.createDate}
          </Card.Text>
          <Card.Text>
            <span className="text-primary">متن پیام : </span> {infoData.text}
          </Card.Text>

          <Link to="/contactUs">
            <Button variant="primary">بازگشت</Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ContactUsInfo;
