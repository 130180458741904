import React, { useEffect, useState } from "react";
import axios from "../../../services/axios";
import { Button, Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { notify } from "../../../components/toastify/toast";
import { useRef } from "react";

const EditUser = () => {
  const [userEdit, setUserEdit] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    subSystemName: "",
    oldSelectedRoleIds: [],
    newSelectedRoleIds: [],
  });
  const [dataSelect, setDataSelect] = useState([]);
  // const [msg, setMsg] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const { id } = useParams();
  const navigate = useNavigate();
  const eyeInputRef = useRef();

  const GetEditedData = async () => {
    const response = await axios.get(`/api/V1/Admin/User/Get-User/${id}`, {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    });
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      // setData(await GetEditedData());
      let d = await GetEditedData();
      d.newSelectedRoleIds = [...d.oldSelectedRoleIds];
      setUserEdit(d);
    };
    fetchApi();
  }, []);

  //checkbox get data
  const getDataSelect = async () => {
    const response = await axios.get("/api/V1/Admin/Role/roles-list", {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    });
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setDataSelect(await getDataSelect());
    };
    fetchApi();
  }, []);

  //handleEdit
  const handleEdit = (e) => {
    setUserEdit({ ...userEdit, [e.target.name]: e.target.value });
  };

  //handle checkbox
  const handleCheckboxChange = (event) => {
    let arr = [...userEdit.newSelectedRoleIds];
    let id = parseInt(event.target.value);
    if (arr.includes(id)) arr = [...arr.filter((item) => item !== id)];
    else arr.push(id);

    userEdit.newSelectedRoleIds = [...arr];

    setUserEdit({ ...userEdit });
  };

  var config = {
    method: "post",
    url: "/api/V1/Admin/User/Edit-User",
    headers: {
      Authorization: token,
      SubSystemKey: subsystemKey,
      "Content-Type": "application/json",
    },
    data: userEdit,
  };

  const handleEditedSubmit = (e) => {
    e.preventDefault();
    axios(config)
      .then((response) => {
        notify(response.data.message, "success");
        // setMsg(response.data.message);

        setTimeout(() => {
          navigate("/user");
        }, 2000);
      })
      .catch((error) => {
        if (error.response.data.errors.FirstName) {
          setErrorFirstName(error.response.data.errors.FirstName);
        }
        if (error.response.data.errors.LastName) {
          setErrorLastName(error.response.data.errors.LastName);
        }
        if (error.response.data.errors.Password) {
          setErrorPassword(error.response.data.errors.Password);
        }
      });
  };
  
  //eye password
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <div className="container mt-1">
      <nav
        aria-label="breadcrumb"
        className="d-flex justify-content-start fw-bold m-1"
      >
        <ol className="breadcrumb">
          <li>
            <Link to="/dashbord" className=" text-decoration-none">
              داشبورد /
            </Link>
          </li>
          <li>
            <Link to="/user" className=" text-decoration-none me-1">
              کاربران /
            </Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page">
            ویرایش کاربر
          </li>
        </ol>
      </nav>
      <Form
        onSubmit={(e) => handleEditedSubmit(e)}
        className="border p-3 w-75 shadow rounded"
      >
        <Form.Group className="mb-5">
          <Form.Label>نام</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            value={userEdit.firstName}
            onChange={(e) => handleEdit(e)}
            placeholder="نام را وارد کنید "
            className="mb-1"
            style={{ border: errorFirstName && "1px solid red" }}
            autoComplete="off"
            // dir='ltr'
          />
          <Form.Label className="d-block text-danger mb-4">
            {errorFirstName && errorFirstName}
          </Form.Label>

          <Form.Label>نام خانوادگی</Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            value={userEdit.lastName}
            onChange={(e) => handleEdit(e)}
            placeholder="نام خانوادگی را وارد کنید"
            className="mb-1"
            style={{ border: errorLastName && "1px solid red" }}
            autoComplete="off"
          />
          <Form.Label className="d-block text-danger mb-4">
            {errorLastName && errorLastName}
          </Form.Label>

          <Form.Label>نام کاربری</Form.Label>
          <Form.Control
            type="text"
            name="userName"
            value={userEdit.userName}
            className="mb-4"
            disabled
          />

          <Form.Label>نام زیرسیستم</Form.Label>
          <Form.Control
            type="text"
            name="subSystemName"
            value={userEdit.subSystemName}
            className="mb-4"
            disabled
          />

          <Form.Label>رمز عبور</Form.Label>
          <div className="position-relative">
          <Form.Control
            type={passwordShown ? "text" : "password"}
            name="password"
            value={userEdit.password}
            onChange={(e) => handleEdit(e)}
            placeholder="رمز عبور"
            className="mb-1"
            autoComplete="off"
            style={{ border: errorPassword && "1px solid red" }}
            ref={eyeInputRef}
            // dir='ltr'
          />
          {eyeInputRef.current?.value && (
              <i
                onClick={togglePasswordVisiblity}
                className={passwordShown ? "fa fa-eye-slash eye-icon" : "fa fa-eye eye-icon"}
              ></i>
          )}
          </div>
          <Form.Label className="d-block text-danger mb-4">
            {errorPassword && errorPassword}
          </Form.Label>

          <div className="w-100 shadow p-1 pointer-event ">
            <h4>نقش های کاربر :</h4>
            {dataSelect.map((item, index) => (
              <div className="w-100 d-flex align-items-center" key={index}>
                <input
                  className="form-check-input m-2"
                  type="checkbox"
                  key={item.id}
                  value={item.id}
                  name={item.name}
                  onChange={handleCheckboxChange}
                  checked={userEdit.newSelectedRoleIds.includes(item.id)}
                />
                <label htmlFor="">{item.name}</label>
              </div>
            ))}
          </div>
        </Form.Group>
        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت ویرایش
        </Button>
        <Link to="/user">
          <Button
            className="action_btn mt-2 p-2 mx-1"
            variant="secondary"
            size="sm"
          >
            انصراف
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default EditUser;
