import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import Home from "./pages/home/Home";
// import ProductContextProvider from "./context/ProductContextProvider";
import Login from "./pages/Login/Login";
import Subsystem from './pages/Subsystem/Subsystem';
import EditSubsystem from "./pages/Subsystem/EditSubsystem/EditSubsystem";
import CreateSubsystem from "./pages/Subsystem/CreateSubsystem/CreateSubsystem";
import User from "./pages/User/User";
import EditUser from "./pages/User/EditUser/EditUser";
import CreateUser from "./pages/User/CreateUser/CreateUser";
import Roles from './pages/Roles/Roles';
import CreateRoles from './pages/Roles/CreateRoles/CreateRoles';
import EditRoles from './pages/Roles/EditRoles/EditRoles';
import Products from "./pages/products/Products";
import CreateProducts from "./pages/products/CreateProducts/CreateProducts";
import EditProducts from "./pages/products/EditProducts/EditProducts";
import CreateRecieps from "./pages/Recieps/CreateRecieps/CreateRecieps";
import Recieps from "./pages/Recieps/Recieps";
import EditRecieps from "./pages/Recieps/EditRecieps/EditRecieps";
import Article from "./pages/Article/Article";
import CreateArticle from "./pages/Article/CreateArticle/CreateArticle";
import EditArticle from "./pages/Article/EditArticle/EditArticle";
import ContactUs from "./pages/ContactUs/ContactUs";
import ContactUsInfo from "./pages/ContactUs/ContactUsInfo";
import Slide from "./pages/Slide/Slide";
import CreateSlide from './pages/Slide/CreateSlide/CreateSlide';
import EditSlide from './pages/Slide/EditSlide/EditSlide';
import WebParts from "./pages/WebParts/WebParts";
import EditWebParts from "./pages/WebParts/EditWebParts/EditWebParts";
import CreateWebParts from "./pages/WebParts/CreateWebParts/CreateWebParts";
import { ToastContainer } from "react-toastify";
import ManageImages from "./pages/ManageImages/ManageImages";
import UploadImage from "./pages/ManageImages/UploadImage";
import SiteSettings from "./pages/SiteSettings/SiteSettings";
import EditSiteSettings from "./pages/SiteSettings/EditSiteSettings";

function App() {
  const [token, setToken] = useState()
  useEffect(() => {
    setToken(localStorage.getItem("token"))
  }, [])
  // let token = localStorage.getItem("token");
  return (
      <Router>
        <Topbar />
        <div className="containerr">

          {token ? (<Sidebar />) : null}
          {/* <Sidebar/> */}
          <Routes>
            {
              (!token &&
                <Route
                  path="/"
                  element={<Login />} />)
            }
            {
              token &&
              (
                <>
                  {/* <Route path="/" element={<Login />} /> */}
                  <Route path="/" element={<Home />} />
                  <Route path="/dashbord" element={<Home />} />
                  <Route path="/subsystem" element={<Subsystem />} />
                  <Route path="/subsystem/create-subsystem" element={<CreateSubsystem />} />
                  <Route path="/subsystem/Edit-SubSystem/:id" element={<EditSubsystem />} />
                  <Route path="/user" element={<User />} />
                  <Route path="/user/create-user" element={<CreateUser />} />
                  <Route path="/user/edit-user/:id" element={<EditUser />} />
                  <Route path="/roles" element={<Roles />} />
                  <Route path="/roles/create-roles" element={<CreateRoles />} />
                  <Route path="/roles/edit-roles/:id" element={<EditRoles />} />
                  <Route path="/products" element={<Products/>} />
                  <Route exact path="/products/create-products" element={<CreateProducts/>} />
                  <Route path="/products/edit-products/:id" element={<EditProducts/>} />
                  <Route path="/recieps" element={<Recieps/>} />
                  <Route path="/recieps/create-recieps" element={<CreateRecieps/>} />
                  <Route path="/recieps/edit-recieps/:id" element={<EditRecieps/>} />
                  <Route path="/article" element={<Article/>} />
                  <Route path="/article/create-article" element={<CreateArticle/>} />
                  <Route path="/article/edit-article/:id" element={<EditArticle/>} />
                  <Route path="/contactUs" element={<ContactUs/>} />
                  <Route path="/contactUs/contactUs-info/:id" element={<ContactUsInfo/>} />
                  <Route path="/slide" element={<Slide/>} />
                  <Route path="/slide/create-slide" element={<CreateSlide/>} />
                  <Route path="/slide/edit-slide/:id" element={<EditSlide/>} />
                  <Route path="/webParts" element={<WebParts/>} />
                  <Route path="/webParts/create-webParts" element={<CreateWebParts/>} />
                  <Route path="/webParts/edit-webParts/:id" element={<EditWebParts/>} />
                  <Route path="/manageImages" element={<ManageImages/>} />
                  <Route path="/manageImages/upload-image" element={<UploadImage/>} />
                  <Route path="/siteSettings" element={<SiteSettings/>} />
                  <Route path="/editSiteSettings" element={<EditSiteSettings/>} />
                </>
              )
            }
          </Routes>
            <ToastContainer/>
        </div>
      </Router>
  );
}

export default App;
