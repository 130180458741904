import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./topbar.css";
import avatar from "../../images/user.png";
import axios from "../../services/axios";

export default function Topbar() {
  const [userInfo, setUserInfo] = useState({});
  // console.log(userInfo);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const location = useLocation();

  const getUserInfo = async () => {
    const response = await axios.get("/api/V1/Admin/User/User-Info", {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    });
    return response.data.data;
  };

  useEffect(() => {
    if (location.pathname) {
      const fetchApi = async () => {
        const data = await getUserInfo();
        setUserInfo(data);
      };
      fetchApi();
    }
  }, [location]);

  //logout
  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("subsystemKey");
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="topbar ">
      <div className="topbarWrapper">
        <div className="logo">
          <span>پنل ادمین</span>
        </div>
        {token && (
          <div className="user-info d-flex align-items-center" dir="ltr">
            <img src={avatar} alt="avatar" />
            <div className="d-flex flex-column mx-2">
              <span className="fw-bold text-primary">
                {userInfo.firstName} {userInfo.lastName}
              </span>
              <div className="d-flex justify-content-between">
                <span className="text-secondary">{userInfo.subSystemName}</span>
                <button
                  onClick={logout}
                  className="glow-on-hover"
                  type="button"
                >
                  <i className="fa fa-power-off  logout"></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
