import React, { useEffect, useState } from "react";
import axios from "../../../services/axios";
import { Button, Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import TreeView from "react-treeview";
import { notify } from "../../../components/toastify/toast";

const EditRoles = () => {
  const [roleEdit, setRoleEdit] = useState({
    name: "",
    subSystemName: "",
    oldSelectedPermissionIds: [],
    newSelectedPermissionIds: [],
  });
  const [dataSelect, setDataSelect] = useState([]);
  // const [msg, setMsg] = useState("");
  const [errorName, setErrorName] = useState("");
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const { id } = useParams();
  const navigate = useNavigate();

  const GetEditedData = async () => {
    const response = await axios.get(
      `/api/V1/Admin/Role/Get-Role/${id}`,
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
          // 'Content-Type': 'application/json'
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      // setData(await GetEditedData());
      let d = await GetEditedData();
      d.newSelectedPermissionIds = [...d.oldSelectedPermissionIds];
      setRoleEdit(d);
    };
    fetchApi();
  }, []);

  //checkbox get data
  const getDataSelect = async () => {
    const response = await axios.get(
      "/api/V1/Admin/Permission?isparent=false",
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setDataSelect(await getDataSelect());
    };
    fetchApi();
  }, []);

  //handleEdit
  const handleEdit = (e) => {
    setRoleEdit({ ...roleEdit, [e.target.name]: e.target.value });
  };

  //handle checkbox
  const handleCheckboxChange = (event) => {
    let arr = [...roleEdit.newSelectedPermissionIds];
    let id = parseInt(event.target.value);
    if (arr.includes(id)) arr = [...arr.filter((item) => item !== id)];
    else arr.push(id);

    roleEdit.newSelectedPermissionIds = [...arr];

    setRoleEdit({ ...roleEdit });
  };

  var config = {
    method: "post",
    url: "/api/V1/Admin/Role/Edit-Role",
    headers: {
      Authorization: token,
      SubSystemKey: subsystemKey,
      "Content-Type": "application/json",
    },
    data: roleEdit,
  };

  const handleEditedSubmit = (e) => {
    e.preventDefault();
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        // setMsg(response.data.message);
        notify(response.data.message, "success");
        setTimeout(() => {
          navigate("/roles");
        }, 2000);
      })
      .catch((error) => {
        notify(error.response.data.message, "error");
        if (error.response.data.errors.Name) {
          setErrorName(error.response.data.errors.Name);
        }
      });
  };
  return (
    <div className="container mt-1">
      <nav aria-label="breadcrumb" className="d-flex justify-content-start fw-bold m-1" >
        <ol className="breadcrumb" >
          <li>
            <Link to="/dashbord" className=" text-decoration-none">داشبورد /</Link>
          </li>
          <li>
            <Link to="/roles" className=" text-decoration-none me-1">نقش ها /</Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page" >
            ویرایش نقش  
          </li>
        </ol>
      </nav>
      <Form
        onSubmit={(e) => handleEditedSubmit(e)}
        className="border p-3 w-75 shadow rounded"
      >
        <Form.Group className="mb-5">
          <Form.Label>نام</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={roleEdit.name}
            onChange={(e) => handleEdit(e)}
            placeholder="نام نقش را وارد کنید "
            className="mb-1"
            style={{ border: errorName && "1px solid red" }}
            autoComplete="off"
            // dir='ltr'
          />
          <Form.Label className="d-block text-danger mb-4">
            {errorName && errorName}
          </Form.Label>

          <Form.Label>نام زیرسیستم</Form.Label>
          <Form.Control
            type="text"
            name="subSystemName"
            value={roleEdit.subSystemName}
            className="mb-4"
            disabled
          />

          <div className="w-100 shadow p-1 pointer-event ">
            <h4>دسترسی ها :</h4>
            {dataSelect.map((item, index) => {
              const showName = item.showName;
              const label = <span>{showName}</span>;
              return (
                <TreeView
                  key={showName + "|" + index}
                  nodeLabel={label}
                  defaultCollapsed={true}
                  className="h4"
                >
                  {item.children.map((role, index) => {
                    return (
                      <div
                        className="w-100 d-flex align-items-center"
                        key={index}
                      >
                        <input
                          className="form-check-input m-2"
                          type="checkbox"
                          key={role.id}
                          value={role.id}
                          name={role.showName}
                          onChange={handleCheckboxChange}
                          checked={roleEdit.newSelectedPermissionIds.includes(
                            role.id
                          )}
                        />
                        <label className="text-primary" htmlFor="">
                          {role.showName}
                        </label>
                      </div>
                    );
                  })}
                </TreeView>
              );
            })}
          </div>
        </Form.Group>
        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت ویرایش <i className="fa fa-fw fa-lg fa-check"></i>
        </Button>
        <Link to="/roles">
          <Button
            className="action_btn mt-2 p-2 mx-1"
            variant="secondary"
            size="sm"
          >
            انصراف <i className="fa fa-fw fa-lg fa-times"></i>
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default EditRoles;
