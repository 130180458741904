import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../services/axios";
import TreeView from "react-treeview";
import "./CreateRoles.css";
import "react-treeview/react-treeview.css";
import { notify } from "../../../components/toastify/toast";

const CreateRoles = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const [dataSelect, setDataSelect] = useState([]);
  const [dataDropdown, setDataDropdown] = useState([]);

  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    selectedPermissionIds: [],
    subsystemId: 0,
  });
  // const [msg, setMsg] = useState("");

  const getDataSelect = async () => {
    const response = await axios.get(
      "/api/V1/Admin/Permission?isparent=false",
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data.data;
  };

  const getDataDropdown = async () => {
    const response = await axios.get(
      "/api/V1/Ui/Home/SubSystems",
      {
        headers: {},
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setDataSelect(await getDataSelect());
    };
    fetchApi();

    const fetchApiDropdown = async () => {
      setDataDropdown(await getDataDropdown());
    };
    fetchApiDropdown();
  }, []);

  const handleSubChange = (e) => {
    data.subsystemId = e.target.value;
    setData({ ...data });
    
  };

  //handle input
  const handle = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };
  //handle checkbox
  const handleCheckboxChange = (event, parentId) => {
    let itemId = parseInt(event.target.value);
    let parentElement = dataSelect.filter((item) => item.id === itemId);
    if (event.target.checked) {
      if (!data.selectedPermissionIds.includes(itemId))
        data.selectedPermissionIds.push(itemId);

      if (parentElement.length === 1) {
        parentElement[0].children.forEach((c) => {
          if (!data.selectedPermissionIds.includes(c.id)) {
            data.selectedPermissionIds.push(c.id);
          }
        });
      } else {
        parentElement = dataSelect.filter(
          (item) => item.id === parseInt(parentId)
        );
        let hasAll = true;
        parentElement[0].children.forEach((c) => {
          if (!data.selectedPermissionIds.includes((x) => x === c.id)) {
            hasAll = false;
          }
        });
        if (hasAll) {
          if (!data.selectedPermissionIds.includes(parentId)) {
            data.selectedPermissionIds.push(parentId);
          }
        }
      }
    } else {
      data.selectedPermissionIds = data.selectedPermissionIds.filter(
        (x) => x !== itemId
      );
      data.selectedPermissionIds = data.selectedPermissionIds.filter(
        (x) => x !== parentId
      );
      if (!parentId) {
        parentElement[0].children.forEach((c) => {
          data.selectedPermissionIds = data.selectedPermissionIds.filter(
            (x) => x !== c.id
          );
        });
      }
    }
    setData({ ...data });
  };

  //submit handler
  let config = {
    method: "post",
    url: "/api/V1/Admin/Role/Create-Role",
    headers: {
      Authorization: token,
      SubSystemKey: subsystemKey,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    axios(config)
      .then(function (response) {
        // setMsg(response.data.message);
        notify(response.data.message, "success");
        setTimeout(() => {
          navigate("/roles");
          setData(data);
        }, 2000);
      })
      .catch(function (error) {
        if (error.response.status !== 400) {
          notify("خطایی در سرور رخ داده است.", "error");
        }
        
        notify(error.response.data.message, "error");
      });
  };

  return (
    <div className="container mt-1">
      <nav aria-label="breadcrumb" className="d-flex justify-content-start fw-bold m-1" >
        <ol className="breadcrumb" >
          <li>
            <Link to="/dashbord" className=" text-decoration-none">داشبورد /</Link>
          </li>
          <li>
            <Link to="/roles" className=" text-decoration-none me-1">نقش ها /</Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page" >
            افزودن نقش جدید 
          </li>
        </ol>
      </nav>
      <Form
        onSubmit={(e) => submitHandler(e)}
        className="border p-3 w-75 shadow rounded"
      >
        <Form.Group className="mb-5">
          <Form.Label> نام نقش :</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={data.name}
            onChange={(e) => handle(e)}
            placeholder="نام نقش را وارد کنید "
            className="mb-4"
            autoComplete="off"
            // dir='ltr'
          />

          <Form.Label>زیر سیستم :</Form.Label>
          <select
            className="form-select mb-4"
            value={data.subsystemId}
            onChange={(e) => handleSubChange(e)}
            aria-label="Default select example"
          >
            {dataDropdown.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>

          <div className="w-100 shadow p-1 pointer-event ">
            <h4>دسترسی ها :</h4>
            {dataSelect.map((item, index) => {
              const showName = item.showName;

              const label = (
                <span>
                  {" "}
                  <input
                    type="checkbox"
                    value={item.id}
                    onChange={(event) =>
                      handleCheckboxChange(event, item.parentId)
                    }
                    name="allSelect"
                    checked={data.selectedPermissionIds.includes(item.id)}
                    // checked={
                    //     item.children.filter((user) => user?.isChecked !== true).length < 1
                    // }
                  />{" "}
                  {showName}
                </span>
              );
              return (
                <TreeView
                  key={showName + "|" + index}
                  nodeLabel={label}
                  defaultCollapsed={true}
                  className="h4"
                >
                  {item.children.map((role, index) => {
                    return (
                      <div
                        className="w-100 d-flex align-items-center"
                        key={index}
                      >
                        <input
                          className="form-check-input m-2"
                          type="checkbox"
                          key={role.id}
                          value={role.id}
                          name={role.showName}
                          onChange={(event) =>
                            handleCheckboxChange(event, role.parentId)
                          }
                          checked={data.selectedPermissionIds.includes(role.id)}
                        />
                        <label className="text-primary" htmlFor="">
                          {role.showName}
                        </label>
                      </div>
                    );
                  })}
                </TreeView>
              );
            })}
          </div>
        </Form.Group>
        <Button
          disabled={!data.name}
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت نقش جدید
        </Button>
        <Link to="/roles">
          <Button
            className="action_btn mt-2 p-2 mx-1"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default CreateRoles;
