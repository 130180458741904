import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../services/axios";
import "./CreateSubsystem.css";
import { notify } from "../../../components/toastify/toast";

const CreateSubsystem = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const [dataSelect, setDataSelect] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    Description: "",
    Url: "",
    SelectedPermissionIds: [],
  });
  // const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const getDataSelect = async () => {
    const response = await axios.get(
      "/api/V1/Admin/Permission?isparent=true",
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setDataSelect(await getDataSelect());
    };
    fetchApi();
  }, []);

  //handle input
  const handle = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
    // console.log("handle", data)
  };

  //handle checkbox
  const handleCheckboxChange = (event) => {
    let newArray = [...data.SelectedPermissionIds, event.target.value];

    if (data.SelectedPermissionIds.includes(event.target.value)) {
      newArray = newArray.filter((item) => item !== event.target.value);
    }
    data.SelectedPermissionIds = newArray;
    setData(data);
  };

  //submit handler
  let config = {
    method: "post",
    url: "/Api/V1/Admin/SubSystem/create-subsystem",
    headers: {
      Authorization: token,
      SubSystemKey: subsystemKey,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    errorMsg && notify({ errorMsg }, "error");
    console.log("submitHandler", data);
    axios(config)
      .then(function (response) {
        setData(response.data);
        notify(response.data.message, "success");
        // setMsg(response.data.message);
        setTimeout(() => {
          navigate("/subsystem");
          setData(data);
        }, 2000);
      })
      .catch(function (error) {
        if (error.response.status !== 400) {
          notify("خطایی در سرور رخ داده است.", "error");
        }
        notify(error.response.data.message, "error");
      });
  };

  return (
    <div className="container mt-1">
      <nav aria-label="breadcrumb" className="d-flex justify-content-start fw-bold m-1" >
        <ol className="breadcrumb" >
          <li>
            <Link to="/dashbord" className=" text-decoration-none">داشبورد /</Link>
          </li>
          <li>
            <Link to="/subsystem" className=" text-decoration-none me-1">زیر سیستم /</Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page" >
            افزودن زیر سیستم جدید 
          </li>
        </ol>
      </nav>
      <Form
        onSubmit={(e) => submitHandler(e)}
        className="border p-3 w-75 shadow rounded"
      >
        <Form.Group className="mb-5">
          <Form.Label>نام</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={data.name}
            onChange={(e) => handle(e)}
            placeholder="نام انگلیسی را وارد کنید "
            className="mb-4"
            autoComplete="off"
            dir="ltr"
          />

          <Form.Label>توضیحات</Form.Label>
          <Form.Control
            type="text"
            name="Description"
            value={data.Description}
            onChange={(e) => handle(e)}
            placeholder="توضیحات فارسی خود را وارد کنید"
            className="mb-4"
            autoComplete="off"
          />

          <Form.Label>وبسایت</Form.Label>
          <Form.Control
            type="text"
            name="Url"
            value={data.Url}
            onChange={(e) => handle(e)}
            placeholder="آدرس وبسایت"
            className="mb-4"
            autoComplete="off"
            dir="ltr"
          />

          <div className="w-100 shadow p-1 pointer-event ">
            <h4>بخش های سیستمی :</h4>
            {dataSelect.map((item, index) => (
              <div className="w-100 d-flex align-items-center" key={index}>
                <input
                  className="form-check-input m-2"
                  type="checkbox"
                  key={item.id}
                  value={item.id}
                  name={item.showName}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="">{item.showName}</label>
              </div>
            ))}
          </div>
        </Form.Group>
        <Button
          disabled={!data.name}
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت زیرسیستم جدید
        </Button>
        <Link to="/subsystem">
          <Button
            className="action_btn mt-2 p-2 mx-1"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default CreateSubsystem;
