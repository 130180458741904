import React, { useRef, useState } from "react";
import axios from "../../../services/axios";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { notify } from "../../../components/toastify/toast";

const CreateSlide = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const navigate = useNavigate();
  // const [msg, setMsg] = useState("");
  const [data, setData] = useState({
    title: "",
    imageFile: null,
    text: "",
    link: "",
  });
  const [imageFileError400 , setImageFileError400] = useState("")
  const [imgFileState, setImgFileState] = useState({ file: "" });
  const imageFileRef = useRef(null);

  //handle input
  const handle = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };

  const handleDeleteImg = () => {
    data.imageFile = null;
    setImgFileState({ file: "" });
    imageFileRef.current.value = null;

    setData({ ...data });
  };

  const handleFile = (e) => {
    data.imageFile = e.target.files[0];
    setImgFileState({ file: URL.createObjectURL(e.target.files[0]) });

    setData({ ...data });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Title", data.title);
    formData.append("ImageFile", data.imageFile);
    formData.append("Text", data.text);
    formData.append("Link", data.link);
    console.log("submitHandler", data);

    var config = {
      method: "post",
      url: "/Api/V1/Admin/Slide/Create-Slide",
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        setData(response.data);
        notify(response.data.message, "success");
        // setMsg(response.data.message);
        setTimeout(() => {
          navigate("/slide");
          setData(data);
        }, 2000);
      })
      .catch(function (error) {
        notify(error.response.data.message, "error");
        if (error.response.data.status === 400) {
          setImageFileError400(error.response.data.errors.ImageFile);
        }
      });
  };

  return (
    <div className="container mt-1">
       <nav aria-label="breadcrumb" className="d-flex justify-content-start fw-bold m-1" >
        <ol className="breadcrumb" >
          <li>
            <Link to="/dashbord" className=" text-decoration-none">داشبورد /</Link>
          </li>
          <li>
            <Link to="/slide" className=" text-decoration-none me-1">اسلاید /</Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page" >
            افزودن اسلاید جدید 
          </li>
        </ol>
      </nav>
      <Form
        className="border p-3 w-100 shadow rounded"
        onSubmit={(e) => submitHandler(e)}
      >
        <Form.Group className="mb-5 ">
          <div className="w-100 m-2 shadow p-2 card d-flex flex-row">
            <div className="w-100 shadow p-2 m-2 rounded card">
              <Form.Label className="mb-2 card-header">عنوان اسلاید</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={data.title}
                onChange={(e) => handle(e)}
                placeholder="عنوان اسلاید را وارد کنید "
                className="mb-4"
                autoComplete="off"
                // dir='ltr'
              />

              <Form.Label className="mb-2 card-header">متن اسلاید</Form.Label>
              <Form.Control
                type="text"
                name="text"
                value={data.text}
                onChange={(e) => handle(e)}
                placeholder="متن اسلاید را وارد کنید "
                className="mb-4"
                autoComplete="off"
                // dir='ltr'
              />

              <Form.Label className="mb-2 card-header">لینک</Form.Label>
              <Form.Control
                type="text"
                name="link"
                value={data.link}
                onChange={(e) => handle(e)}
                placeholder="لینک  اسلاید را وارد کنید "
                className="mb-4"
                autoComplete="off"
                // dir='ltr'
              />
            </div>
          </div>

          <div className="w-100 m-2 shadow p-2 card d-flex flex-row">
            <div className="w-50 m-2 shadow p-2 card">
              <Form.Label className="card-header mb-2">تصویر اسلاید</Form.Label>
              <Form.Control
                ref={imageFileRef}
                type="file"
                name="imageFile"
                onChange={(e) => handleFile(e)}
                className="mb-4"
                autoComplete="off"
                style={{ border: imageFileError400 && "1px solid red" }}
              />
              {imgFileState.file && (
                <div className="card d-flex flex-column">
                  <img
                    className="shadow card-img img-fluid h-100"
                    src={imgFileState.file}
                    alt="imageFile"
                  />
                  <Button
                    className="btn btn-danger "
                    size="sm"
                    type="button"
                    onClick={() => handleDeleteImg()}
                  >
                    حذف عکس{" "}
                  </Button>
                </div>
              )}
              <Form.Label className="d-block text-danger ">
              {imageFileError400 && imageFileError400}
            </Form.Label>
            </div>
          </div>
        </Form.Group>

        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت اسلاید جدید
        </Button>
        <Link to="/slide">
          <Button
            className="action_btn mt-2 p-2 mx-2"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default CreateSlide;
