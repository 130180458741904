import axios from "../../../services/axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { notify } from "../../../components/toastify/toast";

const EditWebParts = () => {
  const [webPartEdit, setWebPartEdit] = useState({
    uniqueName: "",
    body: "",
    specification: "",
  });

  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const { id } = useParams();
  const navigate = useNavigate();
  // const [msg, setMsg] = useState("");
  const editorRef = useRef();
  const [bodyError400, setBodyError400] = useState("");
  const specificationKey = useRef();
  const specificationValue = useRef();

  const GetEditedData = async () => {
    const response = await axios.get(
      `/api/V1/Admin/WebPart/Get-WebPart/${id}`,
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      const data = await GetEditedData();
      data["specificationJson"] = JSON.parse(data.specification);
      setWebPartEdit(data);
    };
    fetchApi();
  }, []);

  //specification handler
  const addSpecification = () => {
    webPartEdit.specificationJson.push({
      Key: specificationKey.current.value,
      Value: specificationValue.current.value,
    });
    webPartEdit.specification = JSON.stringify(webPartEdit.specificationJson);
    setWebPartEdit({ ...webPartEdit });
    specificationKey.current.value = "";
    specificationValue.current.value = "";
  };

  const deleteSpecification = (i) => {
    webPartEdit.specificationJson.splice(i, 1);
    webPartEdit.specification = JSON.stringify(webPartEdit.specificationJson);
    setWebPartEdit({ ...webPartEdit });
  };

  //handleEdit
  const handleEdit = (e) => {
    setWebPartEdit({ ...webPartEdit, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    webPartEdit.body = editorRef.current.getContent();
    setWebPartEdit({ ...webPartEdit });
    var config = {
      method: "post",
      url: "/api/V1/Admin/WebPart/Edit-WebPart",
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
        "Content-Type": "application/json",
      },
      data: webPartEdit,
    };

    axios(config)
      .then((response) => {
        notify(response.data.message, "success");
        // setMsg(response.data.message);

        setTimeout(() => {
          navigate("/webParts");
        }, 2000);
      })
      .catch(function (error) {
        if (error.response.data.status === 400) {
          setBodyError400(error.response.data.errors.Body);
        }
      });
  };
  return (
    <div className="container">
      <nav
        aria-label="breadcrumb"
        className="d-flex justify-content-start fw-bold m-1"
      >
        <ol className="breadcrumb">
          <li>
            <Link to="/dashbord" className=" text-decoration-none">
              داشبورد /
            </Link>
          </li>
          <li>
            <Link to="/webParts" className=" text-decoration-none me-1">
              وب پارت /
            </Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page">
            ویرایش وب پارت
          </li>
        </ol>
      </nav>

      <Form
        className="border p-3 w-100 shadow rounded"
        onSubmit={(e) => submitHandler(e)}
      >
        <Form.Group className="mb-5 ">
          {/* <div className="w-100 m-2 shadow p-2 card d-flex flex-row"> */}
          <div className="w-100 shadow p-2 m-2 rounded card">
            <Form.Label className="mb-2 card-header">نام وب پارت</Form.Label>
            <Form.Control
              type="text"
              name="uniqueName"
              value={webPartEdit.uniqueName}
              onChange={(e) => handleEdit(e)}
              placeholder="نام وب پارت را وارد کنید "
              className="mb-4"
              autoComplete="off"
              disabled
              // dir='ltr'
            />

            <Form.Label>ویرایش محتوای وب </Form.Label>
            <Editor
              onInit={(event, editor) => (editorRef.current = editor)}
              init={{
                selector: "textarea",
                menubar: "false",
                plugins: "link image code",
                directionality: "ltr",
                toolbar:
                  "| undo redo | h1 h2 h3 h4 h5 h6 | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code ",
                language: "fa",
                custom_elements: "i",
                extended_valid_elements: "i[class]",
                valid_children: "+button[i]",
              }}
              initialValue={webPartEdit.body}
            />
            <Form.Label className="d-block text-danger m-2">
              {bodyError400 && bodyError400}
            </Form.Label>

            <Form.Label className="pt-2 ">مشخصات وب پارت</Form.Label>
            <InputGroup className="mb-4" dir="ltr">
              <Button onClick={addSpecification}>+</Button>
              <Form.Control
                type="text"
                name="Key"
                placeholder="کلید"
                autoComplete="off"
                ref={specificationKey}
                dir="rtl"
              />

              <Form.Control
                type="text"
                name="Value"
                placeholder="مقدار"
                autoComplete="off"
                ref={specificationValue}
                dir="rtl"
              />
            </InputGroup>
            {webPartEdit.specificationJson &&
              webPartEdit.specificationJson.map((x, i) => {
                return (
                  <div
                    className="mb-4 rounded border d-flex p-1 bg-secondary justify-content-center text-end flex-column align-items-center"
                    key={i}
                    dir="ltr"
                  >
                    <Form.Label className="border-bottom w-100 p-1 text-light">
                      <span className="text-muted"></span> {x.Key}
                    </Form.Label>

                    <Form.Label className=" border-bottom w-100 p-1 text-light">
                      <span className="text-muted"></span> {x.Value}
                    </Form.Label>

                    <Button
                      className="btn-danger w-100"
                      onClick={() => deleteSpecification(i)}
                    >
                      حذف
                    </Button>
                  </div>
                );
              })}
          </div>

          {/* </div> */}
        </Form.Group>

        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت ویرایش
        </Button>
        <Link to="/webParts">
          <Button
            className="action_btn mt-2 p-2 mx-2"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default EditWebParts;
