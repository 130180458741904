import React, { useRef, useState } from "react";
import axios from "../../services/axios";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { notify } from "../../components/toastify/toast";
const UploadImage = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const navigate = useNavigate();
  // const [msg, setMsg] = useState("");
  const [data, setData] = useState({
    image: null,
  });
  const [imgFileState, setImgFileState] = useState({ file: "" });
  const imageFileRef = useRef(null);

  const handleDeleteImg = () => {
    data.image = null;
    setImgFileState({ file: "" });
    imageFileRef.current.value = null;

    setData({ ...data });
  };

  const handleFile = (e) => {
    data.image = e.target.files[0];
    setImgFileState({ file: URL.createObjectURL(e.target.files[0]) });
    setData({ ...data });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Image", data.image);
    console.log("submitHandler", data);

    var config = {
      method: "post",
      url: "/Api/V1/admin/Content/UploadImage",
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        //   setData(response.data);
        // setMsg(response.data.message);
        notify(response.data.message, "success");
        setTimeout(() => {
          navigate("/manageImages");
          setData(data);
        }, 2000);
      })
      .catch(function (error) {
        notify(error.response.data.message, "error");
      });
  };

  return (
    <div className="container">
      <nav aria-label="breadcrumb" className="d-flex justify-content-start fw-bold m-1" >
        <ol className="breadcrumb" >
          <li>
            <Link to="/dashbord" className=" text-decoration-none">داشبورد /</Link>
          </li>
          <li>
            <Link to="/manageImages" className=" text-decoration-none me-1">تصاویر /</Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page" >
            افزودن تصویر جدید 
          </li>
        </ol>
      </nav>
      <Form
        className="border p-3 w-100 shadow rounded"
        onSubmit={(e) => submitHandler(e)}
      >
        <Form.Group className="mb-5 ">
          <div className="w-50 m-2 shadow p-2 card">
            <Form.Label className="card-header mb-2">افزودن تصویر</Form.Label>
            <Form.Control
              ref={imageFileRef}
              type="file"
              name="imageFile"
              onChange={(e) => handleFile(e)}
              className="mb-4"
              autoComplete="off"
            />
            {imgFileState.file && (
              <div className="card d-flex flex-column">
                <img
                  className="shadow card-img img-fluid h-100"
                  src={imgFileState.file}
                  alt="imageFile"
                />
                <Button
                  className="btn btn-danger "
                  size="sm"
                  type="button"
                  onClick={() => handleDeleteImg()}
                >
                  حذف عکس{" "}
                </Button>
              </div>
            )}
          </div>
        </Form.Group>

        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت تصویر جدید
        </Button>
        <Link to="/manageImages">
          <Button
            className="action_btn mt-2 p-2 mx-2"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default UploadImage;
