import axios from "../../services/axios";
import React, { useEffect, useState } from "react";
import logo from "../../images/zar-logo (1).png";
import zarGroup from "../../images/ezgif.com-gif-maker.jpg";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [userName, setUsername] = useState("UserName");
  const [password, setPassword] = useState("Password");
  const [error, setError] = useState("");
  const [subsystemKey, setSubsystemKey] = useState();
  const [subSystem, setSubsystem] = useState([]);
  const [uiBaseUrl , setUiBaseUrl] = useState()
  console.log(uiBaseUrl);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/V1/Ui/Home/SubSystems")
      .then((res) => {
        setSubsystem(res.data.data);
        setSubsystemKey(res.data.data[0].id);
        setUiBaseUrl(res.data.data[0].url)
      });
  }, []);

  let config = {
    method: "post",
    url: "/api/V1/Admin/Account/login",
    headers: {
      subsystemkey: `${subsystemKey}`,
      "Content-Type": "application/json",
    },
    data: { userName, password },
  };

  const submit = async (e) => {
    e.preventDefault();
    await axios(config)
      .then((response) => {
        const { data } = response.data;
        localStorage.setItem("token", "Bearer " + data);
        localStorage.setItem("subsystemKey", subsystemKey);
        axios.defaults.headers.common["Authorization"] = `Bearer ${data}`;
        navigate("/dashbord");
        window.location.reload();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };
  return (
    <section className="h-100 gradient-form m-auto">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-10">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                <div className="col-lg-6">
                  <div className="card-body p-md-5 mx-md-4">
                    <div className="text-center">
                      <img src={logo} style={{ width: "185px" }} alt="logo" />
                      <h4 className="mt-1 mb-5 pb-1">ما تیم زر هستیم</h4>
                    </div>

                    <form onSubmit={submit}>
                      <p>لطفا وارد حساب کاربری خود شوید</p>

                      <div className="form-outline mb-4">
                        <input
                          type="text"
                          onChange={(e) => setUsername(e.target.value)}
                          id="form2Example11"
                          className="form-control"
                          placeholder="نام کاربری"
                        />
                      </div>

                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          id="form2Example22"
                          className="form-control"
                          placeholder="رمز عبور"
                        />
                      </div>
                      <select
                        className="form-select mb-5"
                        value={subsystemKey}
                        onChange={(e) => setSubsystemKey(e.target.value)}
                        aria-label="Default select example"
                      >
                        {subSystem.map((item) => (
                          <option key={item.name} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>

                      <div className="text-center pt-1 mt-3 mb-5 pb-1">
                        <button
                          className="w-100 btn btn-lg btn-primary border-0 gradient-custom-2 mb-3"
                          type="submit"
                        >
                          ورود
                        </button>
                      </div>
                      {error && (
                        <div className="alert alert-danger mt-2">
                          <p>{error}</p>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                  <img src={zarGroup} alt="zarGroupimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
