import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "../../../services/axios";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { notify } from "../../../components/toastify/toast";

const CreateArticle = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const navigate = useNavigate();
  // const [msg, setMsg] = useState("");
  const [data, setData] = useState({
    seoTitle: "",
    seoUrlTitle: "",
    seoSummary: "",
    seoKeyword: "",
    title: "",
    summary: "",
    body: "",
    seoImageAlt: "",
    imageAlt: "",
    seoImageFile: null,
    imageFile: null,
    isPublish: false,
  });
  const [imgFileState, setImgFileState] = useState({ file: "" });
  const [seoImgstate, setSeoImgState] = useState({ file: "" });
  const imageFileRef = useRef(null);
  const seoImageFileRef = useRef(null);
  const editorRef = useRef();

  //error state
  const [error400, setError400] = useState({});

  //handle input
  const handle = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };

  const handleDeleteImg = (fileType) => {
    if (fileType === "main") {
      data.imageFile = null;
      setImgFileState({ file: "" });
      imageFileRef.current.value = null;
    } else {
      data.seoImageFile = null;
      setSeoImgState({ file: "" });
      seoImageFileRef.current.value = null;
    }
    setData({ ...data });
  };

  //handle is publish
  const handleIsPublish = () => {
    data.isPublish = !data.isPublish;
    setData({ ...data });
  };

  const handleFile = (e, fileType) => {
    if (fileType === "main") {
      data.imageFile = e.target.files[0];
      setImgFileState({ file: URL.createObjectURL(e.target.files[0]) });
    } else {
      data.seoImageFile = e.target.files[0];
      setSeoImgState({ file: URL.createObjectURL(e.target.files[0]) });
    }
    setData({ ...data });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("SeoTitle", data.seoTitle);
    formData.append("SeoUrlTitle", data.seoUrlTitle);
    formData.append("SeoSummary", data.seoSummary);
    formData.append("SeoKeyword", data.seoKeyword);
    formData.append("SeoImageAlt", data.seoImageAlt);
    formData.append("Title", data.title);
    formData.append("Summary", data.summary);
    formData.append("Body", (data.body = editorRef.current.getContent()));
    formData.append("ImageAlt", data.imageAlt);
    formData.append("SeoImageFile", data.seoImageFile);
    formData.append("ImageFile", data.imageFile);
    formData.append("IsPublish", data.isPublish);

    var config = {
      method: "post",
      url: "/Api/V1/Admin/Article/Create-Article",
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        setData(response.data);
        // setMsg(response.data.message);
        notify(response.data.message, "success");

        setTimeout(() => {
          navigate("/article");
          setData(data);
        }, 2000);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          notify(error.response.data.message, "error");
        }

        if (error.response.status === 400) {
          notify(error.response.data.message, "error");

          for (const item in error.response.data.errors) {
            error400[item] = error.response.data.errors[item][0];
          }
          setError400({ ...error400 });
        }
        if (error.response.status === 500) {
          notify("خطایی در سرور رخ داده است", "error");
        }
      });
  };

  return (
    <div className="container">
      <nav
        aria-label="breadcrumb"
        className="d-flex justify-content-start fw-bold m-1"
      >
        <ol className="breadcrumb">
          <li>
            <Link to="/dashbord" className=" text-decoration-none">
              داشبورد /
            </Link>
          </li>
          <li>
            <Link to="/article" className=" text-decoration-none me-1">
              اخبار و مقالات /
            </Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page">
            افزودن خبر جدید
          </li>
        </ol>
      </nav>
      <Form
        className="border p-3 w-100 shadow rounded"
        onSubmit={(e) => submitHandler(e)}
      >
        <Form.Group className="mb-5 ">
          <div className="w-100 m-2 shadow p-2 card d-flex flex-row">
            <div className="w-100 shadow p-2 m-2 rounded card">
              <Form.Label className="mb-2 card-header">
                اخبار و مقالات
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={data.title}
                onChange={(e) => handle(e)}
                placeholder="عنوان خبر را وارد کنید "
                className="mb-1"
                autoComplete="off"
                // dir='ltr'
              />
              {error400.Title && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.Title}
                </div>
              )}

              <Form.Label>خلاصه</Form.Label>
              <Form.Control
                as="textarea"
                name="summary"
                value={data.summary}
                onChange={(e) => handle(e)}
                placeholder="خلاصه ای از توضیحات را وارد کنید "
                className="mb-1"
                autoComplete="off"
                rows="3"
                cols="3"
                // dir='ltr'
              />
              {error400.Summary && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.Summary}
                </div>
              )}

              <Form.Label>متن خبر</Form.Label>
              <Editor
                onInit={(event, editor) => (editorRef.current = editor)}
                init={{
                  selector: "textarea",
                  menubar: "false",
                  plugins: "link image code",
                  directionality: "ltr",
                  toolbar:
                    "| undo redo | h1 h2 h3 h4 h5 h6 | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code ",
                  language: "fa",
                }}
              />
              {error400.Body && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.Body}
                </div>
              )}

              <Form.Check
                type="switch"
                id="custom-switch"
                label="انتشار در سایت"
                className="w-25 mt-4"
                value={data.isPublish}
                onChange={(e) => handleIsPublish(e)}
              />
            </div>

            <div className="w-100 m-2 shadow p-2 card ">
              <Form.Label className="mb-2 card-header">سئو</Form.Label>
              <Form.Control
                type="text"
                name="seoTitle"
                value={data.seoTitle}
                onChange={(e) => handle(e)}
                placeholder="عنوان سئو را وارد کنید"
                autoComplete="off"
                className="mb-1"
              />
              {error400.SeoTitle && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.SeoTitle}
                </div>
              )}

              <Form.Label className="">خلاصه ای از سئو</Form.Label>
              <Form.Control
                as="textarea"
                name="seoSummary"
                value={data.seoSummary}
                onChange={(e) => handle(e)}
                placeholder="خلاصه سئو را وارد کنید"
                autoComplete="off"
                className="mb-1"
              />
              {error400.SeoSummary && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.SeoSummary}
                </div>
              )}

              <Form.Label className="">عنوان در آدرس</Form.Label>
              <Form.Control
                type="text"
                name="seoUrlTitle"
                value={data.seoUrlTitle}
                onChange={(e) => handle(e)}
                placeholder="عنوان در آدرس سئو را وارد کنید"
                autoComplete="off"
                className="mb-1"
              />
              {error400.SeoUrlTitle && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.SeoUrlTitle}
                </div>
              )}

              <Form.Label className="">کلمات کلیدی</Form.Label>
              <Form.Control
                as="textarea"
                name="seoKeyword"
                value={data.seoKeyword}
                onChange={(e) => handle(e)}
                placeholder="کلمات کلیدی سئو را وارد کنید"
                autoComplete="off"
              />
              {error400.SeoKeyword && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.SeoKeyword}
                </div>
              )}
            </div>
          </div>

          <div className="w-100 m-2 shadow p-2 card d-flex flex-row">
            <div className="w-50 m-2 shadow p-2 card">
              <Form.Label className="card-header mb-2">تصویر اصلی</Form.Label>
              <Form.Control
                ref={imageFileRef}
                type="file"
                name="imageFile"
                onChange={(e) => handleFile(e, "main")}
                className="mb-4"
                autoComplete="off"
              />
              {imgFileState.file && (
                <div className="card d-flex flex-column">
                  <img
                    className="shadow card-img img-fluid h-100"
                    src={imgFileState.file}
                    alt="imageFile"
                  />
                  <Button
                    className="btn btn-danger "
                    size="sm"
                    type="button"
                    onClick={() => handleDeleteImg("main")}
                  >
                    حذف عکس{" "}
                  </Button>
                </div>
              )}
            </div>

            <div className="w-50 m-2 shadow p-2 card">
              <Form.Label className="card-header mb-2">تصویر سئو</Form.Label>
              <Form.Control
                type="file"
                name="seoImageFile"
                ref={seoImageFileRef}
                onChange={(e) => handleFile(e, "seo")}
                className="mb-4 "
                autoComplete="off"
              />
              {seoImgstate.file && (
                <div className="card d-flex flex-column">
                  <img
                    className="shadow card-img img-fluid h-100 "
                    src={seoImgstate.file}
                    alt="seoImage"
                  />
                  <Button
                    className="btn btn-danger"
                    size="sm"
                    onClick={() => handleDeleteImg("seo")}
                  >
                    حذف عکس{" "}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Form.Group>

        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت خبر جدید
        </Button>
        <Link to="/article">
          <Button
            className="action_btn mt-2 p-2 mx-2"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default CreateArticle;
