import axios from "../../services/axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../services/axios";
import "./widgetSm.css";

export default function WidgetSm() {
  const [products, setProducts] = useState({ items: [], totalCount: 0 });
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");

  const getData = async () => {
    const response = await axios.get(
      "/Api/V1/Admin/Product",
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setProducts(await getData());
    };
    fetchApi();
  }, []);

  const size = 5
  const productSlice = products.items.slice(0 , size)

  return (
    <div className="widgetSm bg-dark text-light rounded shadow ms-2">
      <span className="widgetSmTitle text-info">محصولات زراویل</span>
      {productSlice.map((item, index) => (
        <ul className="widgetSmList" key={index}>
          <li className="widgetSmListItem">
            <div className="w-25">
              <img
                src={`${window.Configs["admin-base-url"]}/Api/V1/ui/Content/Image?entityName=0&imageName=${item.image}`}
                alt="oilImage"
                className="widgetSmImg shadow"
              />
            </div>
            <div className="widgetSmUser w-75">
              <span className="widgetSmUsername th">{item.title}</span>
              <span className="widgetSmUserTitle">
                {item.volume} {item.volumeUnit}
              </span>
            </div>
            {/* <button className="widgetSmButton">Display</button> */}
          </li>
        </ul>
      ))}
    </div>
  );
}
