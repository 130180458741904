import axios from "../../services/axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./featuredInfo.css";

export default function FeaturedInfo() {
  const [webPart, setWebPart] = useState({ items: [], totalCount: 0 });
  const [article, setArticle] = useState({ items: [], totalCount: 0 });
  const [slide, setSlide] = useState({ items: [], totalCount: 0 });
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");

  const getDataWebPart = async () => {
    const response = await axios.get("/api/V1/Admin/WebPart", {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    });
    return response.data.data;
  };

  const getDataArticle = async () => {
    const response = await axios.get("/Api/v1/Admin/Article", {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    });
    return response.data.data;
  };

  const getDataSlide = async () => {
    const response = await axios.get("/Api/V1/Admin/Slide", {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    });
    return response.data.data;
  };

  useEffect(() => {
    const fetchApiWebpart = async () => {
      setWebPart(await getDataWebPart());
    };
    fetchApiWebpart();

    const fetchApiArticle = async () => {
      setArticle(await getDataArticle());
    };
    fetchApiArticle();

    const fetchApiSlide = async () => {
      setSlide(await getDataSlide());
    };
    fetchApiSlide();

  }, []);

  return (
    <div className="featured">
      <div className="featuredItem bg-dark text-light">
        <span className="featuredTitle">وب پارت ها</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{webPart.totalCount} </span>
        </div>
        <Link to="/webparts">
          <span className="featuredSub">رفتن به وب پارت</span>
        </Link>
      </div>

      <div className="featuredItem bg-dark text-light">
        <span className="featuredTitle">اخبار</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{article.totalCount}</span>
        </div>
        <Link to="/article">
          <span className="featuredSub">رفتن به اخبار</span>
        </Link>
      </div>
      <div className="featuredItem bg-dark text-light">
        <span className="featuredTitle">اسلاید ها</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{slide.totalCount}</span>
        </div>
        <Link to="/slide">
          <span className="featuredSub">رفتن به اسلاید</span>
        </Link>
      </div>
    </div>
  );
}
