import React, { useEffect, useRef, useState } from "react";
import axios from "../../../services/axios";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { notify } from "../../../components/toastify/toast";

const EditSlide = () => {
  const [slideEdit, setSlideEdit] = useState({
    title: "",
    oldImage: "",
    imageFile: null,
    text: "",
    link: "",
  });
  console.log(slideEdit);
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const navigate = useNavigate();
  // const [msg, setMsg] = useState("");
  const { id } = useParams();
  const [imgFileState, setImgFileState] = useState({ file: "" });
  const imageFileRef = useRef(null);

  const GetEditedData = async () => {
    const response = await axios.get(
      `/Api/V1/Admin/Slide/Get-Slide/${id}`,
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setSlideEdit(await GetEditedData());
    };
    fetchApi();
  }, []);

  //handleEdit
  const handleEdit = (e) => {
    setSlideEdit({ ...slideEdit, [e.target.name]: e.target.value });
  };

  const handleFileEdit = (e) => {
    slideEdit.imageFile = e.target.files[0];
    setImgFileState({ file: URL.createObjectURL(e.target.files[0]) });
    // slideEdit.isDeleteImage = false;

    setSlideEdit({ ...slideEdit });
  };

  //delete image
  // const handleDeleteImg = () => {
  //   slideEdit.oldImage = null;
  //   setImgFileState({ file: "" });
  //   imageFileRef.current.value = null;
  //   // slideEdit.isDeleteImage = true;

  //   setSlideEdit({ ...slideEdit });
  // };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("Title", slideEdit.title);
    formData.append("Text", slideEdit.text);
    formData.append("Link", slideEdit.link);
    formData.append("Id", id);
    formData.append("ImageFile", slideEdit.imageFile);

    var config = {
      method: "post",
      url: "/Api/V1/Admin/Slide/Edit-Slide",
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        // setMsg(response.data.message);
        notify(response.data.message, "success");

        setTimeout(() => {
          navigate("/slide");
        }, 2000);
      })
      .catch(function (error) {
        notify(error.response.data.message, "error");
      });
  };

  return (
    <div className="container">
      <nav aria-label="breadcrumb" className="d-flex justify-content-start fw-bold m-1" >
        <ol className="breadcrumb" >
          <li>
            <Link to="/dashbord" className=" text-decoration-none">داشبورد /</Link>
          </li>
          <li>
            <Link to="/slide" className=" text-decoration-none me-1">اسلاید /</Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page" >
            ویرایش اسلاید 
          </li>
        </ol>
      </nav>
      <Form
        className="border p-3 w-100 shadow rounded"
        onSubmit={(e) => submitHandler(e)}
      >
        <Form.Group className="mb-5 ">
          <div className="w-100 m-2 shadow p-2 card d-flex flex-row">
            <div className="w-100 shadow p-2 m-2 rounded card">
              <Form.Label className="mb-2 card-header">عنوان اسلاید</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={slideEdit.title}
                onChange={(e) => handleEdit(e)}
                placeholder="عنوان اسلاید را وارد کنید "
                className="mb-4"
                autoComplete="off"
                // dir='ltr'
              />

              <Form.Label className="mb-2 card-header">متن اسلاید</Form.Label>
              <Form.Control
                type="text"
                name="text"
                value={slideEdit.text}
                onChange={(e) => handleEdit(e)}
                placeholder="متن اسلاید را وارد کنید "
                className="mb-4"
                autoComplete="off"
                // dir='ltr'
              />

              <Form.Label className="mb-2 card-header">لینک</Form.Label>
              <Form.Control
                type="text"
                name="link"
                value={slideEdit.link}
                onChange={(e) => handleEdit(e)}
                placeholder="لینک اسلاید را وارد کنید "
                className="mb-4"
                autoComplete="off"
                // dir='ltr'
              />
            </div>

            <div className="w-100 m-2 shadow p-2 card">
              <Form.Label className="card-header mb-2">تصویر اسلاید</Form.Label>
              <Form.Control
                ref={imageFileRef}
                type="file"
                name="imageFile"
                onChange={(e) => handleFileEdit(e)}
                className="mb-4"
                autoComplete="off"
              />
              {!imgFileState.file ? (
                slideEdit.oldImage && (
                  <div className="card d-flex flex-column">
                    <img
                      className="shadow card-img img-fluid h-100"
                      src={`${window.Configs["admin-base-url"]}/Api/V1/ui/Content/Image?entityName=3&imageName=${slideEdit.oldImage}`}
                      alt="imageFile"
                    />
                    {/* <Button
                      className="btn btn-danger "
                      size="sm"
                      type="button"
                      onClick={() => handleDeleteImg()}
                    >
                      حذف عکس{" "}
                    </Button> */}
                  </div>
                )
              ) : (
                <div className="card d-flex flex-column">
                  <img
                    className="shadow card-img img-fluid h-100"
                    src={imgFileState.file}
                    alt="imageFile"
                  />
                  {/* <Button
                    className="btn btn-danger "
                    size="sm"
                    type="button"
                    onClick={() => handleDeleteImg()}
                  >
                    حذف عکس{" "}
                  </Button> */}
                </div>
              )}
            </div>
          </div>
        </Form.Group>

        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت ویرایش
        </Button>
        <Link to="/slide">
          <Button
            className="action_btn mt-2 p-2 mx-2"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default EditSlide;
