import React, { useEffect, useState } from "react";
import axios from "../../services/axios";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ErrorCard from "../../components/Error/ErrorCard";
import { notify } from "../../components/toastify/toast";
import attachment from "../../images/attachment.png";

const ManageImages = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const [data, setData] = useState({ items: [], totalCount: 0 });
  const [errorName, setErrorName] = useState("");
  const [statusError, setStatusError] = useState("");

  //start pagination
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setCurrentItems(data.items);
    setPageCount(Math.ceil(data.totalCount / pageSize));
  }, [itemOffset, pageSize, pageNum, data]);

  const handlePageClick = (event) => {
    const pn = event.selected + 1;
    const newOffset = (pn * pageSize) % data.totalCount;
    setItemOffset(newOffset);
    setPageNum(pn);
  };
  //end of pagination

  const getData = () => {
    return new Promise((resolve) => {
      axios
        .get("/Api/V1/Admin/Content/FilterImages", {
          headers: {
            Authorization: token,
            SubSystemKey: subsystemKey,
          },
          params: {
            page: `${pageNum}`,
            pagesize: `${pageSize}`,
          },
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          // resolve({ items: [], totalCount: 0 });
          setErrorName(error.response.data.message);
          setStatusError(error.response.status);
        });
    });
  };

  useEffect(() => {
    const fetchApi = async () => {
      setData(await getData());
    };
    fetchApi();
  }, [pageNum, pageSize]);

  const handleCopyUrl = (src) => {
    // navigator.clipboard.writeText(
    //   `${uiBaseUrl}Api/V1/ui/Content/Image?entityName=4&imageName=${src}`
    // );

    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      navigator.clipboard.writeText(
        `${window.Configs["ui-base-url"]}Api/V1/ui/Content/Image?entityName=4&subsystem=${subsystemKey}&imageName=${src}`
      );
      !errorName && notify("آدرس در کلیپ بورد ذخیره شد", "success");
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = `${window.Configs["ui-base-url"]}Api/V1/ui/Content/Image?entityName=4&subsystem=${subsystemKey}&imageName=${src}`;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      !errorName && notify("آدرس در کلیپ بورد ذخیره شد", "success");

      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const getImage = (imageName) => {
    const address = `${window.Configs["admin-base-url"]}/Api/V1/ui/Content/Image?entityName=4&subsystem=${subsystemKey}&imageName=${imageName}`;
    if (!address.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return attachment;
    }

    return address;
  };

  return !errorName ? (
    <div className="container m-4 shadow d-flex justify-content-around h-vh-100 flex-wrap">
      <div className="d-flex justify-content-center mt-2">
        <h2 className="text-primary">مدیریت تصاویر</h2>
      </div>
      <div className=" d-flex justify-content-around align-items-baseline w-100 mt-4 mb-0">
        <div className="d-flex align-items-baseline w-75">
          <ReactPaginate
            breakLabel="..."
            nextLabel="صفحه بعد >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< صفحه قبل"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
          />
          <select
            value={pageSize}
            onChange={(e) => setPageSize(e.target.value)}
            className="selector mx-2 rounded text-primary border-primary"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        {/* <p className="me-3 text-primary fw-bold">تعداد کل تصاویر : <span className="text-info">{data.totalCount} عدد</span> </p> */}
        <div>
          <Link to="/manageImages/upload-image">
            <Button variant="primary">افزودن عکس</Button>
          </Link>
        </div>
      </div>
      {currentItems.map((item, index) => (
        <Card
          style={{ width: "16rem", height: "20rem" }}
          className="shadow mx-1 my-2"
          key={index}
        >
          <Card.Img
            variant="top"
            // className="h-50"
            style={{ Width: "100%", height: "9rem" }}
            src={getImage(item.imageName)}
          />
          <Card.Body>
            <Card.Footer
              style={{ Width: "18rem", height: "6rem" }}
              className="mb-2"
            >
              {item.imageName}
            </Card.Footer>
            <Button
              variant="primary"
              onClick={() => handleCopyUrl(item.imageName)}
            >
              کپی آدرس
            </Button>
          </Card.Body>
        </Card>
      ))}
    </div>
  ) : (
    <ErrorCard statusError={statusError} errorName={errorName} />
  );
};

export default ManageImages;
