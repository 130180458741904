import "./widgetLg.css";
import axios from "../../services/axios";
import { useEffect, useState } from "react";

export default function WidgetLg() {
  const [reciepe, setReciepe] = useState({ items: [], totalCount: 0 });
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const getData = async () => {
    const response = await axios.get("/Api/v1/Admin/Recipe", {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    });
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setReciepe(await getData());
    };
    fetchApi();
  }, []);

  const size = 6;
  const reciepeSlice = reciepe.items.slice(0, size);
  return (
    <div className="widgetLg bg-dark text-light rounded me-2">
      <h3 className="widgetLgTitle text-info">دستور پخت زر اویل</h3>
      <table className="widgetLgTable" >
        <tbody>
          {reciepeSlice.map((item, index) => (
            <tr className="widgetLgTr" key={index}>
              <td className="widgetLgUser">
                <img
                  src={`${window.Configs["admin-base-url"]}/Api/V1/ui/Content/Image?entityName=1&imageName=${item.image}`}
                  alt=""
                  className="widgetLgImg"
                />
                <span className="widgetLgName">{item.title}</span>
              </td>
              <td className="widgetLgDate">{item.createDate}</td>
              <td className="widgetLgAmount">{item.lastUpdate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
