import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../services/axios";
import { notify } from "../../../components/toastify/toast";

const CreateUser = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const [dataSelect, setDataSelect] = useState([]);
  const [dataDropdown, setDataDropdown] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    selectedRoleIds: [],
    subsystemId: 0,
  });
  const eyeInputRef = useRef();
  // const [msg, setMsg] = useState("");

  const getDataSelect = async () => {
    const response = await axios.get("/api/V1/Admin/Role/roles-list", {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    });
    return response.data.data;
  };

  const getDataDropdown = async () => {
    const response = await axios.get("/api/V1/Ui/Home/SubSystems", {
      headers: {},
    });
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      setDataSelect(await getDataSelect());
    };
    fetchApi();

    const fetchApiDropdown = async () => {
      setDataDropdown(await getDataDropdown());
    };
    fetchApiDropdown();
  }, []);

  const handleSubChange = (e) => {
    data.subsystemId = e.target.value;
    setData({ ...data });
  };

  //handle input
  const handle = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };

  //handle checkbox
  const handleCheckboxChange = (event) => {
    let newArray = [...data.selectedRoleIds, event.target.value];

    if (data.selectedRoleIds.includes(event.target.value)) {
      newArray = newArray.filter((item) => item !== event.target.value);
    }
    data.selectedRoleIds = newArray;
    setData(data);
  };

  //submit handler
  let config = {
    method: "post",
    url: "/api/V1/Admin/User/Create-User",
    headers: {
      Authorization: token,
      SubSystemKey: subsystemKey,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: data,
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("submitHandler", data);
    axios(config)
      .then(function (response) {
        notify(response.data.message, "success");
        // setData(response.data);
        // setMsg(response.data.message);
        setTimeout(() => {
          navigate("/user");
          setData(data);
        }, 2000);
      })
      .catch(function (error) {
        if (error.response.status !== 400) {
          notify("خطایی در سرور رخ داده است.", "error");
        }

        notify(error.response.data.message, "error");
      });
  };

  //eye password
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <div className="container mt-1">
      <nav
        aria-label="breadcrumb"
        className="d-flex justify-content-start fw-bold m-1"
      >
        <ol className="breadcrumb">
          <li>
            <Link to="/dashbord" className=" text-decoration-none">
              داشبورد /
            </Link>
          </li>
          <li>
            <Link to="/user" className=" text-decoration-none me-1">
              کاربران /
            </Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page">
            افزودن کاربر جدید
          </li>
        </ol>
      </nav>
      <Form
        onSubmit={(e) => submitHandler(e)}
        className="border p-3 w-75 shadow rounded"
      >
        <Form.Group className="mb-5">
          <Form.Label>نام</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            value={data.firstName}
            onChange={(e) => handle(e)}
            placeholder="نام را وارد کنید "
            className="mb-4"
            autoComplete="off"
            // dir='ltr'
          />

          <Form.Label>نام خانوادگی</Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            value={data.lastName}
            onChange={(e) => handle(e)}
            placeholder="نام خانوادگی را وارد کنید"
            className="mb-4"
            autoComplete="off"
          />

          <Form.Label>نام کاربری</Form.Label>
          <Form.Control
            type="text"
            name="userName"
            value={data.userName}
            onChange={(e) => handle(e)}
            placeholder="نام کاربری را وارد کنید"
            className="mb-4"
            autoComplete="off"
          />

          <Form.Label>رمز عبور</Form.Label>
          <div className="position-relative">
            <Form.Control
              type={passwordShown ? "text" : "password"}
              name="password"
              value={data.password}
              onChange={(e) => handle(e)}
              placeholder="رمز عبور"
              className="mb-4"
              autoComplete="off"
              ref={eyeInputRef}
            />
            {eyeInputRef.current?.value && (
              <i
                onClick={togglePasswordVisiblity}
                className={
                  passwordShown
                    ? "fa fa-eye-slash eye-icon"
                    : "fa fa-eye eye-icon"
                }
              ></i>
            )}
          </div>

          <Form.Label>زیر سیستم :</Form.Label>
          <select
            className="form-select mb-4"
            value={data.subsystemId}
            onChange={(e) => handleSubChange(e)}
            aria-label="Default select example"
          >
            {dataDropdown.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>

          <div className="w-100 shadow p-1 pointer-event ">
            <h4>نقش های کاربر :</h4>
            {dataSelect.map((item, index) => (
              <div className="w-100 d-flex align-items-center" key={index}>
                <input
                  className="form-check-input m-2"
                  type="checkbox"
                  key={item.id}
                  value={item.id}
                  name={item.name}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="">{item.name}</label>
              </div>
            ))}
          </div>
        </Form.Group>
        <Button
          disabled={
            !data.firstName ||
            !data.lastName ||
            !data.password ||
            !data.userName
          }
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت کاربر جدید
        </Button>
        <Link to="/user">
          <Button
            className="action_btn mt-2 p-2 mx-1"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default CreateUser;
