import React, { useEffect, useState } from 'react'
import axios from '../../../services/axios';
import { Button, Form } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import "./EditSubsystem.css"
import { notify } from '../../../components/toastify/toast';

const EditSubsystem = () => {

  // const [data, setData] = useState({})
  const [userEdit, setUserEdit] = useState({
    name: "", description: "", url: "",
    oldSelectedPermissionIds: [], newSelectedPermissionIds: []
  })
  const [dataSelect, setDataSelect] = useState([])
  // const [msg, setMsg] = useState("")
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey")
  const { id } = useParams()
  const navigate = useNavigate()


  const GetEditedData = async () => {
    const response = await axios.get(`/api/V1/Admin/SubSystem/Get-SubSystem/${id}`, {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
        'Content-Type': 'application/json'
      }
    }
    )
    return response.data.data
  }


  useEffect(() => {
    const fetchApi = async () => {
      // setData(await GetEditedData());
      let d = await GetEditedData();
      d.newSelectedPermissionIds = [...d.oldSelectedPermissionIds];

      setUserEdit(d)
    };
    fetchApi();

  }, []);

  //checkbox get data
  const getDataSelect = async () => {
    const response = await axios.get('/api/V1/Admin/Permission?isparent=true', {
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      }
    });
    return response.data.data;

  };

  useEffect(() => {
    const fetchApi = async () => {
      setDataSelect(await getDataSelect());
    };
    fetchApi();

  }, []);

  //handleEdit
  const handleEdit = (e) => {
    setUserEdit({ ...userEdit, [e.target.name]: e.target.value })
  }

  //handle checkbox
  const handleCheckboxChange = event => {
    let arr = [...userEdit.newSelectedPermissionIds];
    let id = parseInt(event.target.value);
    if (arr.includes(id))
      arr = [...arr.filter(item => item !== id)]
    else
      arr.push(id)

    userEdit.newSelectedPermissionIds = [...arr];

    setUserEdit({ ...userEdit })
  };


  var config = {
    method: 'post',
    url: '/api/V1/Admin/SubSystem/Edit-SubSystem',
    headers: {
      Authorization: token,
      SubSystemKey: subsystemKey,
      'Content-Type': 'application/json'
    },
    data: userEdit
  };

  const handleEditedSubmit = (e) => {
    e.preventDefault()
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        notify(response.data.message, "success");
        // setMsg(response.data.message)

        setTimeout(() => {
          navigate("/subsystem")
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="container edit">
       <nav aria-label="breadcrumb" className="d-flex justify-content-start fw-bold m-1" >
        <ol className="breadcrumb" >
          <li>
            <Link to="/dashbord" className=" text-decoration-none">داشبورد /</Link>
          </li>
          <li>
            <Link to="/subsystem" className=" text-decoration-none me-1">زیر سیستم /</Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page" >
            ویرایش زیر سیستم  
          </li>
        </ol>
      </nav>
      <Form onSubmit={(e) => handleEditedSubmit(e)} className="border p-3 w-75 shadow rounded">
        <Form.Group className='mb-5' >
          <Form.Label>نام</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={userEdit.name}
            disabled
            onChange={(e) => handleEdit(e)}
            placeholder="نام انگلیسی را وارد کنید  "
            className='mb-4'
            autoComplete='off'
            dir='ltr'
          />

          <Form.Label>توضیحات</Form.Label>
          <Form.Control
            type="text"
            name="description"
            value={userEdit.description}
            onChange={(e) => handleEdit(e)}
            placeholder="توضیحات فارسی خود را وارد کنید"
            className='mb-4'
            autoComplete='off'
          />

          <Form.Label>وبسایت</Form.Label>
          <Form.Control
            type="text"
            name="url"
            value={userEdit.url}
            onChange={(e) => handleEdit(e)}
            placeholder="آدرس وبسایت"
            className='mb-4'
            autoComplete='off'
            dir='ltr'
          />

          <div className='w-100 shadow p-1 pointer-event '>
            <h4>بخش های سیستمی :</h4>
            {dataSelect.map((item, index) => (



              <div className='w-100 d-flex align-items-center' key={index}>
                <input
                  className='form-check-input m-2'
                  type="checkbox"
                  key={item.id}
                  value={item.id}
                  name={item.showName}
                  onChange={handleCheckboxChange}
                  checked={userEdit.newSelectedPermissionIds.includes(item.id)}

                />
                <label htmlFor="">{item.showName}</label>
              </div>))
            }
          </div>
        </Form.Group>
        <Button className="action_btn p-2" variant="primary" type="submit" size='sm'>
          ثبت ویرایش
        </Button>
        <Link to="/subsystem">
          <Button className="action_btn p-2 mx-1" variant="secondary" size='sm'>
            انصراف
          </Button>
        </Link>
        {/* {msg &&
        <div className="alert alert-success mt-2">
          {msg}
        </div>} */}
      </Form>
    </div>
  )
}

export default EditSubsystem