import React, { useEffect, useState } from "react";
import { Button, Table, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ErrorCard from "../../components/Error/ErrorCard";
import axios from "../../services/axios";
import Loading from "../../components/loading/Loading";

const SiteSettings = () => {
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const [data, setData] = useState();
  console.log(data && data);
  const [errorName, setErrorName] = useState("");
  const [statusError, setStatusError] = useState("");
  const navigate = useNavigate();

  const getData = () => {
    return new Promise((resolve) => {
      axios
        .get("/api/V1/Admin/SiteSetting/get-siteSetting", {
          headers: {
            Authorization: token,
            SubSystemKey: subsystemKey,
          },
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          // resolve({ items: [], totalCount: 0 });
          setErrorName(error.response.data.message);
          setStatusError(error.response.status);
          console.log(error);
        });
    });
  };

  const fetchApi = async () => {
    setData(await getData());
  };
  useEffect(() => {
    fetchApi();
  }, []);

  //loading
  const [loader, setLoader] = useState(false);
  const refreshHandler = () => {
    setLoader(true);
    fetchApi();
    setLoader(false);
  };

  const reportHandler = (e) => {
    e.preventDefault()
    var config = {
      method: "get",
      url: `/api/V1/Ui/home/sitemap`,
      headers: {
        'SubSystemKey': '-2147483590'
      },
    };

    // let currentDate = new Date();
    // let time =
    //   currentDate.getHours() +
    //   ":" +
    //   currentDate.getMinutes() +
    //   ":" +
    //   currentDate.getSeconds();

    // var today = new Date();

    // var date =
    //   today.getFullYear() +
    //   "-" +
    //   (today.getMonth() + 1) +
    //   "-" +
    //   today.getDate() +
    //   "-" +
    //   time;

    axios(config)
      .then((response) => {
        const element = document.createElement("a");
        const file = new Blob([response.data], {
          type: "application/xml",
        });
        element.href = URL.createObjectURL(file);
        element.download = "sitemap";
        document.body.appendChild(element);
        element.click();
      })
      .catch(function (error) {
        // console.log(error);
        // notify("نام کاربری یا رمز عبور اشتباه است", "error");
      });
  };

  return !errorName ? (
    loader ? (
      <Loading />
    ) : (
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-center">
          <h2 className="text-primary">مدیریت تنظیمات</h2>
        </div>
        <Card className="w-75">
          <Card.Header>تنظیمات</Card.Header>
          <Card.Body>
            <blockquote className="blockquote">
              <p>
                Lat :
                <p className="bg-secondary bg-opacity-10 rounded p-1 m-1 fs-6 text-secondary">
                  {data && data.lat}
                </p>
              </p>
            </blockquote>
            <blockquote className="blockquote">
              <p>
                Long :
                <p className="bg-secondary bg-opacity-10 rounded p-1 m-1 fs-6 text-secondary">
                  {data && data.long}
                </p>
              </p>
            </blockquote>
            <blockquote className="blockquote">
              <p>
                Robots :
                <p className="bg-secondary bg-opacity-10 rounded p-1 m-1 fs-6 text-secondary">
                  {data && data.robots}
                </p>
              </p>
            </blockquote>
            <blockquote className="blockquote">
              <p>
                توضیحات سئو در صفحه اول :
                <p className="bg-secondary bg-opacity-10 rounded p-1 fs-6 text-secondary">
                  {data && data.description}
                </p>
              </p>
            </blockquote>
            
            <Form
              className=" w-100 mb-3"
              onSubmit={(event) => reportHandler(event)}
            >
              <Form.Label className="mb-2 card-header bg-secondary bg-opacity-10 w-100">
                sitemap
              </Form.Label>
              <div dir="rtl">
                <Button className="px-4 button" size="sm" type="submit">
                  ساخت sitemap
                </Button>
              </div>
            </Form>
            <div dir="ltr">
              <Button
                variant="primary"
                onClick={() => navigate("/editSiteSettings")}
              >
                تغییر تنظیمات
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  ) : (
    <ErrorCard statusError={statusError} errorName={errorName} />
  );
};

export default SiteSettings;
