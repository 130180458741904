import React, { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import axios from "../../../services/axios";
import { notify } from "../../../components/toastify/toast";

const EditProducts = () => {
  const [productEdit, setProductEdit] = useState({
    seoTitle: "",
    seoUrlTitle: "",
    seoSummary: "",
    seoKeyword: "",
    title: "",
    summary: "",
    body: "",
    oldImage: "",
    oldSeoImage: "",
    shopLink: "",
    shopLinkJson: [],
    specification: "",
    specificationJson: [],
    seoImageFile: null,
    imageFile: null,
    isDeleteImage: false,
    isDeleteSeoImage: false,
    isPublish: "",
  });
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const { id } = useParams();
  const navigate = useNavigate();
  // const [msg, setMsg] = useState("");
  const [imgFileState, setImgFileState] = useState({ file: "" });
  const [seoImgstate, setSeoImgState] = useState({ file: "" });
  const imageFileRef = useRef(null);
  const seoImageFileRef = useRef(null);
  const shoplinkName = useRef();
  const shoplinkUrl = useRef();
  const editorRef = useRef();
  const specificationKey = useRef();
  const specificationTitle = useRef();
  const specificationValue = useRef();

  //specification handler
  const addSpecification = () => {
    productEdit.specificationJson.push({
      Key: specificationKey.current.value,
      Title: specificationTitle.current.value,
      Value: specificationValue.current.value,
    });
    setProductEdit({ ...productEdit });
    specificationKey.current.value = "";
    specificationTitle.current.value = "";
    specificationValue.current.value = "";
  };

  const deleteSpecification = (i) => {
    productEdit.specificationJson.splice(i, 1);
    setProductEdit({ ...productEdit });
  };

  //error state
  const [error400, setError400] = useState({});

  const addShopLink = () => {
    productEdit.shopLinkJson.push({
      Name: shoplinkName.current.value,
      Url: shoplinkUrl.current.value,
    });
    setProductEdit({ ...productEdit });
  };

  const deleteShopLink = (i) => {
    productEdit.shopLinkJson.splice(i, 1);
    setProductEdit({ ...productEdit });
  };

  const GetEditedData = async () => {
    const response = await axios.get(
      `/Api/V1/Admin/Product/get-product/${id}`,
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      const data = await GetEditedData();
      data.shopLinkJson = JSON.parse(data.shopLink);
      data.specificationJson = JSON.parse(data.specification)
      setProductEdit(data);
    };
    fetchApi();
  }, []);

  //handleEdit
  const handleEdit = (e) => {
    setProductEdit({ ...productEdit, [e.target.name]: e.target.value });
  };

  const handleEditIsPublish = () => {
    productEdit.isPublish = !productEdit.isPublish;
    setProductEdit({ ...productEdit });
  };

  const handleFileEdit = (e, fileType) => {
    if (fileType === "main") {
      productEdit.imageFile = e.target.files[0];
      setImgFileState({ file: URL.createObjectURL(e.target.files[0]) });
      productEdit.isDeleteImage = false;
    } else {
      productEdit.seoImageFile = e.target.files[0];
      setSeoImgState({ file: URL.createObjectURL(e.target.files[0]) });
      productEdit.isDeleteSeoImage = false;
    }
    setProductEdit({ ...productEdit });
  };

  //delete image
  const handleDeleteImg = (fileType) => {
    if (fileType === "main") {
      productEdit.oldImage = null;
      setImgFileState({ file: "" });
      imageFileRef.current.value = null;
      productEdit.isDeleteImage = true;
    } else {
      productEdit.oldSeoImage = null;
      setSeoImgState({ file: "" });
      seoImageFileRef.current.value = null;
      productEdit.isDeleteSeoImage = true;
    }
    setProductEdit({ ...productEdit });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("SeoTitle", productEdit.seoTitle);
    formData.append("SeoUrlTitle", productEdit.seoUrlTitle);
    formData.append("SeoSummary", productEdit.seoSummary);
    formData.append("SeoKeyword", productEdit.seoKeyword);
    formData.append("Title", productEdit.title);
    formData.append("Summary", productEdit.summary);
    formData.append(
      "Body",
      (productEdit.body = editorRef.current.getContent())
    );
    formData.append("ShopLink", JSON.stringify(productEdit.shopLinkJson));
    formData.append("Specification", JSON.stringify(productEdit.specificationJson));
    formData.append("SeoImageFile", productEdit.seoImageFile);
    formData.append("ImageFile", productEdit.imageFile);
    formData.append("IsDeleteImage", productEdit.isDeleteImage);
    formData.append("IsDeleteSeoImage", productEdit.isDeleteSeoImage);
    formData.append("Id", id);
    formData.append("IsPublish", productEdit.isPublish);
    console.log("submitHandler", productEdit);

    var config = {
      method: "post",
      url: "/Api/V1/Admin/Product/Edit-Product",
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then((response) => {
        notify(response.data.message, "success");

        // setMsg(response.data.message);

        setTimeout(() => {
          navigate("/products");
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          notify(error.response.data.message, "error");
        }
        console.log(error);

        if (error.response.status === 400) {
          notify(error.response.data.message, "error");

          for (const item in error.response.data.errors) {
            error400[item] = error.response.data.errors[item][0];
          }
          setError400({ ...error400 });
        }
        if (error.response.status === 500) {
          notify("خطایی در سرور رخ داده است", "error");
        }
      });
  };

  return (
    <div className="container">
      <nav
        aria-label="breadcrumb"
        className="d-flex justify-content-start fw-bold m-1"
      >
        <ol className="breadcrumb">
          <li className=" ">
            <Link to="/dashbord" className=" text-decoration-none">
              داشبورد /
            </Link>
          </li>
          <li>
            <Link to="/products" className=" text-decoration-none me-1">
              محصولات /
            </Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page">
            ویرایش محصول
          </li>
        </ol>
      </nav>
      <Form
        className="border p-3 w-100 shadow rounded"
        onSubmit={(e) => handleEditSubmit(e)}
      >
        <Form.Group className="mb-5">
          <div className="w-100 m-2 shadow p-2 card d-flex flex-row">
            <div className="w-50 shadow p-2 m-2 rounded card ">
              <Form.Label className="mb-2 card-header">عنوان محصول</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={productEdit.title}
                onChange={(e) => handleEdit(e)}
                placeholder="عنوان محصول را وارد کنید "
                className="mb-3"
                autoComplete="off"
                // dir='ltr'
              />
              {error400.Title && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.Title}
                </div>
              )}

              <Form.Label>خلاصه</Form.Label>
              <Form.Control
                as="textarea"
                name="summary"
                value={productEdit.summary}
                onChange={(e) => handleEdit(e)}
                placeholder="خلاصه ای از توضیحات را وارد کنید "
                className="mb-3"
                autoComplete="off"
                rows="3"
                cols="3"
                // dir='ltr'
              />
              {error400.Summary && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.Summary}
                </div>
              )}

              {/* <Form.Label className="pt-2 ">حجم و واحد حجم</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  type="text"
                  name="volume"
                  placeholder="حجم"
                  autoComplete="off"
                  value={productEdit.volume}
                  onChange={(e) => handleEdit(e)}
                  dir="rtl"
                />
                <Form.Control
                  type="text"
                  name="volumeUnit"
                  placeholder="واحد حجم"
                  autoComplete="off"
                  value={productEdit.volumeUnit}
                  onChange={(e) => handleEdit(e)}
                  dir="rtl"
                />
              </InputGroup> */}

              <Form.Check
                type="switch"
                id="custom-switch"
                label="انتشار در سایت"
                className="w-25 mt-4"
                value={productEdit.isPublish}
                checked={productEdit.isPublish}
                onChange={(e) => handleEditIsPublish(e)}
              />
            </div>

            <div className="w-50 m-2 shadow p-2 card ">
              <Form.Label className="mb-2 card-header">سئو</Form.Label>
              <Form.Control
                type="text"
                name="seoTitle"
                value={productEdit.seoTitle}
                onChange={(e) => handleEdit(e)}
                placeholder="عنوان سئو را وارد کنید"
                className="mb-3"
                autoComplete="off"
              />
              {error400.SeoTitle && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.SeoTitle}
                </div>
              )}

              <Form.Label className="">خلاصه ای از سئو</Form.Label>
              <Form.Control
                as="textarea"
                name="seoSummary"
                value={productEdit.seoSummary}
                onChange={(e) => handleEdit(e)}
                placeholder="خلاصه سئو را وارد کنید"
                className="mb-3"
                autoComplete="off"
              />
              {error400.SeoSummary && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.SeoSummary}
                </div>
              )}

              <Form.Label className="">عنوان در آدرس</Form.Label>
              <Form.Control
                type="text"
                name="seoUrlTitle"
                value={productEdit.seoUrlTitle}
                onChange={(e) => handleEdit(e)}
                placeholder="عنوان در آدرس سئو را وارد کنید"
                className="mb-3"
                autoComplete="off"
              />
              {error400.SeoUrlTitle && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.SeoUrlTitle}
                </div>
              )}

              <Form.Label className="">کلمات کلیدی</Form.Label>
              <Form.Control
                as="textarea"
                name="seoKeyword"
                value={productEdit.seoKeyword}
                onChange={(e) => handleEdit(e)}
                placeholder="کلمات کلیدی سئو را وارد کنید"
                className="mb-3"
                autoComplete="off"
              />
              {error400.SeoKeyword && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.SeoKeyword}
                </div>
              )}
            </div>
          </div>
          <div className="w-100 m-2 shadow p-2 card d-flex flex-row flex-column">

          <Form.Label>متن محصول</Form.Label>
              <Editor
                onInit={(event, editor) => (editorRef.current = editor)}
                init={{
                  selector: "textarea",
                  menubar: "false",
                  plugins: "link image code",
                  directionality: "ltr",
                  toolbar:
                    "| undo redo | h1 h2 h3 h4 h5 h6 | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code ",
                  language: "fa",
                }}
                initialValue={productEdit.body}
              />
              {error400.Body && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.Body}
                </div>
              )}

              <Form.Label className="pt-2 ">لینک خرید</Form.Label>
              <InputGroup className="mb-4" dir="ltr">
                <Button onClick={addShopLink}>+</Button>
                <Form.Control
                  type="text"
                  name="Name"
                  placeholder="نام لینک"
                  autoComplete="off"
                  ref={shoplinkName}
                />
                <Form.Control
                  type="text"
                  name="Url"
                  placeholder="آدرس"
                  autoComplete="off"
                  ref={shoplinkUrl}
                />
              </InputGroup>
              {productEdit.shopLinkJson &&
                productEdit.shopLinkJson.map((x, i) => {
                  return (
                    <div
                      className="mb-4 rounded border d-flex p-1 bg-secondary justify-content-center text-end flex-column align-items-center"
                      key={i}
                      dir="ltr"
                    >
                      <Form.Label className="border-bottom text-light w-100 p-1">
                        {x.Name}
                      </Form.Label>
                      <Form.Label className="border-bottom text-light w-100 p-1">
                        {x.Url}
                      </Form.Label>
                      <Button
                        className="btn-danger w-100"
                        onClick={() => deleteShopLink(i)}
                      >
                        -
                      </Button>
                    </div>
                  );
                })}

              <Form.Label className="pt-2 ">مشخصات محصول</Form.Label>
              <InputGroup className="mb-4" dir="ltr">
                <Button onClick={addSpecification}>+</Button>
                <Form.Control
                  type="text"
                  name="Key"
                  placeholder="کلید"
                  autoComplete="off"
                  ref={specificationKey}
                  dir="rtl"
                />
                <Form.Control
                  type="text"
                  name="Title"
                  placeholder="نام نمایشی"
                  autoComplete="off"
                  ref={specificationTitle}
                  dir="rtl"
                />
                <Form.Control
                  type="text"
                  name="Value"
                  placeholder="مقدار"
                  autoComplete="off"
                  ref={specificationValue}
                  dir="rtl"
                />
              </InputGroup>
              {productEdit.specificationJson &&
                productEdit.specificationJson.map((x, i) => {
                  return (
                    <div
                      className="mb-4 rounded border d-flex p-1 bg-secondary justify-content-center text-end flex-column align-items-center"
                      key={i}
                      dir="ltr"
                    >
                      <Form.Label className="border-bottom w-100 p-1 text-light">
                        <span className="text-muted"></span> {x.Key}
                      </Form.Label>
                      <Form.Label className="border-bottom w-100 p-1 text-light">
                        <span className="text-muted"></span> {x.Title}
                      </Form.Label>
                      <Form.Label className=" border-bottom w-100 p-1 text-light">
                        <span className="text-muted"></span> {x.Value}
                      </Form.Label>

                      <Button
                        className="btn-danger w-100"
                        onClick={() => deleteSpecification(i)}
                      >
                        حذف
                      </Button>
                    </div>
                  );
                })}
          </div>

          <div className="w-100 m-2 shadow p-2 card d-flex flex-row">
            <div className="w-50 m-2 shadow p-2 card">
              <Form.Label className="card-header mb-2">تصویر اصلی</Form.Label>
              <Form.Control
                ref={imageFileRef}
                type="file"
                name="imageFile"
                // value={}
                onChange={(e) => handleFileEdit(e, "main")}
                className="mb-4 "
                autoComplete="off"
              />
              {!imgFileState.file ? (
                productEdit.oldImage && (
                  <div className="card d-flex flex-column">
                    <img
                      className="shadow card-img img-fluid h-100"
                      src={`${window.Configs["admin-base-url"]}/Api/V1/ui/Content/Image?entityName=0&imageName=${productEdit.oldImage}`}
                      alt="imageFile"
                    />
                    <Button
                      className="btn btn-danger "
                      size="sm"
                      type="button"
                      onClick={() => handleDeleteImg("main")}
                    >
                      حذف عکس{" "}
                    </Button>
                  </div>
                )
              ) : (
                <div className="card d-flex flex-column">
                  <img
                    className="shadow card-img img-fluid h-100"
                    src={imgFileState.file}
                    alt="imageFile"
                  />
                  <Button
                    className="btn btn-danger "
                    size="sm"
                    type="button"
                    onClick={() => handleDeleteImg("main")}
                  >
                    حذف عکس{" "}
                  </Button>
                </div>
              )}
            </div>

            <div className="w-50 m-2 shadow p-2 card">
              <Form.Label className="card-header mb-2">تصویر سئو</Form.Label>
              <Form.Control
                ref={seoImageFileRef}
                type="file"
                name="seoImageFile"
                onChange={(e) => handleFileEdit(e, "seo")}
                className="mb-4 "
                autoComplete="off"
              />
              {!seoImgstate.file ? (
                productEdit.oldSeoImage && (
                  <div className="card d-flex flex-column">
                    <img
                      className="shadow card-img img-fluid h-100"
                      src={`${window.Configs["admin-base-url"]}/Api/V1/ui/Content/Image?entityName=0&imageName=${productEdit.oldSeoImage}`}
                      alt="seoImage"
                    />
                    <Button
                      className="btn btn-danger"
                      size="sm"
                      onClick={() => handleDeleteImg("seo")}
                    >
                      حذف عکس{" "}
                    </Button>
                  </div>
                )
              ) : (
                <div className="card d-flex flex-column">
                  <img
                    className="shadow card-img img-fluid h-100 "
                    src={seoImgstate.file}
                    alt="seoImage"
                  />
                  <Button
                    className="btn btn-danger"
                    size="sm"
                    onClick={() => handleDeleteImg("seo")}
                  >
                    حذف عکس{" "}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Form.Group>

        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت ویرایش
        </Button>
        <Link to="/products">
          <Button
            className="action_btn mt-2 p-2 mx-2"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default EditProducts;
