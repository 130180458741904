export const dataSidebar = [
  {
    id: -2147483648,
    name: "Admin.Users",
    showName: "مدیریت کاربران",
    icon: "fa-user",
    link: "/user",
  },
  {
    id: -2147483643,
    name: "Admin.Roles",
    showName: "مدیریت نقش ها",
    icon: "fa-users",
    link: "/roles",
  },
  {
    id: -2147483638,
    name: "Admin.SubSystems",
    showName: "مدیریت زیر سیستم ها",
    icon: "fa-database",
    link: "/subsystem",
  },
  {
    id: -2147483633,
    name: "Admin.Products",
    showName: "مدیریت محصولات",
    icon: "fa-shopping-basket",
    link: "/products",
  },
  {
    id: -2147483628,
    name: "Admin.Recipes",
    showName: "مدیریت دستور پخت",
    icon: "fa-cutlery",
    link: "/recieps",
  },
  {
    id: -2147483623,
    name: "Admin.Contacts",
    showName: "مدیریت تماس با ما",
    icon: "fa-phone",
    link: "/contactUs",
  },
  {
    id: -2147483620,
    name: "Admin.Articles",
    showName: "مدیریت اخبار و مقالات",
    icon: "fa-newspaper-o",
    link: "/article",
  },
  {
    id: -2147483615,
    name: "Admin.WebParts",
    showName: "مدیریت وب پارت ها",
    icon: "fa-file-o",
    link: "/webparts",
  },
  {
    id: -2147483610,
    name: "Admin.Slides",
    showName: "مدیریت اسلاید ها",
    icon: "fa-sliders",
    link: "/slide",
  },
  {
    id: -2147483605,
    name: "Admin.Images",
    showName: "مدیریت تصاویر",
    icon: "fa-picture-o",
    link: "/manageImages",
  },
  {
    id: -2147483603,
    name: "Admin.SiteSettings",
    showName: "مدیریت تنطیمات",
    icon: "fa-cogs",
    link: "/siteSettings",
  },
];
