import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../services/axios";
import { notify } from "../../components/toastify/toast";

const EditSiteSettings = () => {
  const [siteSetting, setSiteSetting] = useState({
    lat: "",
    long: "",
    robots: "",
    description: "",
  });
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const navigate = useNavigate();
  // const [msg, setMsg] = useState("");

  //error state
  const [error400, setError400] = useState({});

  const GetEditedData = async () => {
    const response = await axios.get(
      "/api/V1/Admin/SiteSetting/get-siteSetting",
      {
        headers: {
          Authorization: token,
          SubSystemKey: subsystemKey,
        },
      }
    );
    return response.data;
  };

  useEffect(() => {
    const fetchApi = async () => {
      const data = await GetEditedData();
      setSiteSetting(data);
    };
    fetchApi();
  }, []);

  //handleEdit
  const handleEdit = (e) => {
    setSiteSetting({ ...siteSetting, [e.target.name]: e.target.value });
  };

  var config = {
    method: "post",
    url: "/api/V1/Admin/SiteSetting/edit-siteSetting",
    headers: {
      Authorization: token,
      SubSystemKey: subsystemKey,
      "Content-Type": "application/json",
    },
    data: siteSetting,
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        notify(response.data.message, "success");
        // setMsg(response.data.message)

        setTimeout(() => {
          navigate("/siteSettings");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <nav
        aria-label="breadcrumb"
        className="d-flex justify-content-start fw-bold m-1"
      >
        <ol className="breadcrumb">
          <li className=" ">
            <Link to="/dashbord" className=" text-decoration-none">
              داشبورد /
            </Link>
          </li>
          <li>
            <Link to="/siteSettings" className=" text-decoration-none me-1">
              تنظیمات /
            </Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page">
            ویرایش تنظیمات
          </li>
        </ol>
      </nav>
      <Form
        className=" p-3 w-75  rounded mb-3"
        onSubmit={(e) => handleEditSubmit(e)}
      >
        <Form.Group className="">
          <div className="w-100 p-2  d-flex flex-row">
            <div className="w-100 shadow p-2 rounded card ">
              <Form.Label className="mb-2 card-header w-100">Lat</Form.Label>
              <Form.Control
                type="text"
                name="lat"
                value={siteSetting.lat}
                onChange={(e) => handleEdit(e)}
                placeholder="lat "
                className="mb-3 w-50"
                autoComplete="off"
                // dir='ltr'
              />

              <Form.Label className="mb-2 card-header w-100">Long</Form.Label>
              <Form.Control
                type="text"
                name="long"
                value={siteSetting.long}
                onChange={(e) => handleEdit(e)}
                placeholder="long"
                className="mb-3 w-50"
                autoComplete="off"
                // dir='ltr'
              />

              {/*<Form.Label className="mb-2 card-header w-100">Robots</Form.Label>
              <Form.Control
                as="textarea"
                name="robots"
                value={siteSetting.robots}
                onChange={(e) => handleEdit(e)}
                placeholder="خلاصه ای از توضیحات را وارد کنید "
                className="mb-3 w-75"
                autoComplete="off"
                rows="3"
                cols="3"
                // dir='ltr'
              />
              {error400.Summary && (
                <div className="alert alert-danger p-1 m-1">
                  {error400.Summary}
                </div>
              )} */}

              <Form.Label className="mb-2 card-header w-100">
                توضیحات سئو در صفحه اول
              </Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={siteSetting.description}
                onChange={(e) => handleEdit(e)}
                placeholder="خلاصه ای از توضیحات را وارد کنید "
                className="mb-3 w-75"
                autoComplete="off"
                rows="3"
                cols="3"
                // dir='ltr'
              />
            </div>
          </div>
          <Button
            className="action_btn mt-1 p-2 me-2"
            variant="primary"
            type="submit"
            size="sm"
          >
            ثبت ویرایش
          </Button>
          <Link to="/siteSettings">
            <Button
              className="action_btn mt-1 p-2 mx-2"
              variant="secondary"
              size="sm"
            >
              بازگشت
            </Button>
          </Link>
        </Form.Group>

        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default EditSiteSettings;
