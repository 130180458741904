import React, { useRef, useState } from "react";
import axios from "../../../services/axios";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { notify } from "../../../components/toastify/toast";

const CreateWebParts = () => {
  const [data, setData] = useState({
    uniqueName: "",
    body: "",
    specificationJson: [],
    specification: "[]",
  });
  const token = localStorage.getItem("token");
  const subsystemKey = localStorage.getItem("subsystemKey");
  const navigate = useNavigate();
  // const [msg, setMsg] = useState("");
  const editorRef = useRef();
  const specificationKey = useRef();
  const specificationValue = useRef();

  //specification handler
  const addSpecification = () => {
    data.specificationJson.push({
      Key: specificationKey.current.value,
      Value: specificationValue.current.value,
    });
    data.specification = JSON.stringify(data.specificationJson);
    setData({ ...data });
    specificationKey.current.value = "";
    specificationValue.current.value = "";
  };

  const deleteSpecification = (i) => {
    data.specificationJson.splice(i, 1);
    data.specification = JSON.stringify(data.specificationJson);
    setData({ ...data });
  };

  //error state
  const [bodyError400, setBodyError400] = useState("");
  const [uniqueNameError400, setUniqueNameError400] = useState("");

  //handle input
  const handle = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };

  //submit handler
  let config = {
    method: "post",
    url: "/Api/V1/Admin/WebPart/Create-WebPart",
    headers: {
      Authorization: token,
      SubSystemKey: subsystemKey,
      "Content-Type": "application/json",
    },
    data: data,
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    data.body = editorRef.current.getContent();
    setData({ ...data });
    axios(config)
      .then(function (response) {
        setData(response.data);
        notify(response.data.message, "success");
        // setMsg(response.data.message);
        setTimeout(() => {
          navigate("/webParts");
          setData(data);
        }, 2000);
      })
      .catch(function (error) {
        notify(error.response.data.message, "error");
        if (error.response.data.errors.Body) {
          setBodyError400(error.response.data.errors.Body);
        }
        if (error.response.data.errors.UniqueName) {
          setUniqueNameError400(error.response.data.errors.UniqueName);
        }
      });
  };

  return (
    <div className="container">
      <nav
        aria-label="breadcrumb"
        className="d-flex justify-content-start fw-bold m-1"
      >
        <ol className="breadcrumb">
          <li>
            <Link to="/dashbord" className=" text-decoration-none">
              داشبورد /
            </Link>
          </li>
          <li>
            <Link to="/webParts" className=" text-decoration-none me-1">
              وب پارت /
            </Link>
          </li>
          <li className="breadcrumb-item active me-1" aria-current="page">
            افزودن وب پارت جدید
          </li>
        </ol>
      </nav>

      <Form
        className="border p-3 w-100 shadow rounded"
        onSubmit={(e) => submitHandler(e)}
      >
        <Form.Group className="mb-4 ">
          {/* <div className="w-100 m-2 shadow p-2 card d-flex flex-row"> */}
          <div className="w-100 shadow p-2 m-2 rounded card">
            <Form.Label className="mb-2 card-header">نام وب پارت</Form.Label>
            <Form.Control
              type="text"
              name="uniqueName"
              value={data.uniqueName}
              style={{ border: uniqueNameError400 && "1px solid red" }}
              onChange={(e) => handle(e)}
              placeholder="نام وب پارت را وارد کنید "
              className="mb-1"
              autoComplete="off"
              // dir='ltr'
            />
            <Form.Label className="d-block text-danger m-1">
              {uniqueNameError400 && uniqueNameError400}
            </Form.Label>

            <Form.Label>محتوای وب پارت</Form.Label>
            <Editor
              onInit={(event, editor) => (editorRef.current = editor)}
              init={{
                selector: "textarea",
                menubar: "false",
                plugins: "link image code",
                directionality: "ltr",
                toolbar:
                  "| undo redo | h1 h2 h3 h4 h5 h6 | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code ",
                language: "fa",
                custom_elements: "i",
                extended_valid_elements: "i[class]",
                valid_children: "+button[i]",
              }}
            />

            <Form.Label className="d-block text-danger mb-4">
              {bodyError400 && bodyError400}
            </Form.Label>

            <Form.Label className="pt-2 ">مشخصات وب پارت</Form.Label>
            <InputGroup className="mb-4" dir="ltr">
              <Button onClick={addSpecification}>+</Button>
              <Form.Control
                type="text"
                name="Key"
                placeholder="کلید"
                autoComplete="off"
                ref={specificationKey}
                dir="rtl"
              />

              <Form.Control
                type="text"
                name="Value"
                placeholder="مقدار"
                autoComplete="off"
                ref={specificationValue}
                dir="rtl"
              />
            </InputGroup>
            {data.specificationJson &&
              data.specificationJson.map((x, i) => {
                return (
                  <div
                    className="mb-4 rounded border d-flex p-1 bg-secondary justify-content-center text-end flex-column align-items-center"
                    key={i}
                    dir="ltr"
                  >
                    <Form.Label className="border-bottom w-100 p-1 text-light">
                      <span className="text-muted"></span> {x.Key}
                    </Form.Label>

                    <Form.Label className=" border-bottom w-100 p-1 text-light">
                      <span className="text-muted"></span> {x.Value}
                    </Form.Label>

                    <Button
                      className="btn-danger w-100"
                      onClick={() => deleteSpecification(i)}
                    >
                      حذف
                    </Button>
                  </div>
                );
              })}
          </div>

          {/* </div> */}
        </Form.Group>

        <Button
          className="action_btn mt-2 p-2"
          variant="primary"
          type="submit"
          size="sm"
        >
          ثبت وب پارت جدید
        </Button>
        <Link to="/webParts">
          <Button
            className="action_btn mt-2 p-2 mx-2"
            variant="secondary"
            size="sm"
          >
            بازگشت
          </Button>
        </Link>
        {/* {msg && <div className="alert alert-success mt-2">{msg}</div>} */}
      </Form>
    </div>
  );
};

export default CreateWebParts;
