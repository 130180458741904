import { useState, useEffect } from "react";
import axios from "../../services/axios";
import "./sidebar.css";
import { Link,useNavigate } from "react-router-dom";
import { dataSidebar } from "./dataSidebar";

export default function Sidebar() {
  const [dataSidebarLive, setDataSidebarLive] = useState([]);
  const navigate = useNavigate()

    //get token
    const token = localStorage.getItem("token");
    const subsystemKey = localStorage.getItem("subsystemKey")


  //accessSidebar
  const nameItemsSidebar = dataSidebar.map((item) => item);
  const nameItemSidebarLive = dataSidebarLive.map((item) => item);

  const isSameItem = (a, b) => a.name === b.name;
  const hasAccessMenu = (local, live, compareFunction) =>
    local.filter((leftName) =>
      live.some((rightName) => compareFunction(leftName, rightName))
    );
  const commonItem = hasAccessMenu(
    nameItemsSidebar,
    nameItemSidebarLive,
    isSameItem
  );

  const result = [...commonItem];
  // end accessSidebar

  //get Api data admin-menu
  useEffect(() => {
    var config = {
      method: "get",
      url: "/api/V1/Admin/Permission/Admin-Menu",
      headers: {
        Authorization: token,
        SubSystemKey: subsystemKey,
      },
    };

    axios(config)
      .then((response) => {
        setDataSidebarLive(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  // console.log(token);

  //logout
  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("subsystemKey")
    navigate("/");
    window.location.reload()
  };

  return (
    token && (
      <div className="sidebar">
        <div className="sidebarWrapper">
          <div className="sidebarMenu">
            <h3 className="sidebarTitle">داشبورد</h3>
            <ul className="sidebarList">
              <Link to="/dashbord" className="link">
                <li className="sidebarListItem active">
                  <i className="fa fa-table "></i>
                  صفحه اصلی
                </li>
              </Link>
            </ul>
          </div>
          <div className="sidebarMenu">
            <h3 className="sidebarTitle">منوی دسترسی</h3>
            <ul className="sidebarList">
              {result.map((item, index) => (
                <Link to={`${item.link}`} className="link" key={index}>
                  <li className="sidebarListItem mb-4">
                    <i className={`fa ${item.icon}`}></i>
                    {item.showName}
                  </li>
                </Link>
              ))}
            </ul>
          </div>

          <div className="sidebarMenu">
            <h3 className="sidebarTitle">خروج از سیستم</h3>
            <ul className="sidebarList">
              <Link to="/" onClick={logout} className="link">
                <li className="sidebarListItem">
                  <i className="fa fa-sign-out"></i>
                  خروج
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    )
  );
}
